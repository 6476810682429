import { DATE_TIME_FORMAT } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import moment from 'moment';
import {
  Button,
  formatCurrency,
  FunnelOutlined,
  PaginationProps,
  Tag,
} from 'tera-dls';
import Searching from './Searching';
import { useEffect, useState } from 'react';
import FilterData from './Filter';
import { APPLY_TYPE } from 'pages/Affiliate/Config/constants';
import AffiliateApi from 'pages/Affiliate/Overview/api';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import {
  ORDER_STATUS,
  ORDER_STATUS_COLOR,
} from 'pages/Affiliate/Overview/constants';
import { removeNullObject } from '_common/utils';

const Order = () => {
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [params, setParams] = useState<any>();
  const { id } = useParams();

  const {
    data: response,
    refetch: fetchData,
    isFetching,
  } = useQuery(
    ['get-affiliate-overview-list-order', id, params],
    () =>
      AffiliateApi.getListOrder({
        affiliate_id: id,
        ...removeNullObject(params),
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!id,
    },
  );

  useEffect(() => {
    id && fetchData();
  }, [id]);

  const columns: any = [
    {
      title: 'Mã đơn hàng',
      dataIndex: 'code',
      width: 150,
      render: (val) => <span className="text-blue-500">{val}</span>,
    },
    {
      title: 'Người mua',
      dataIndex: 'student_name',
      width: 150,
      render: (val) => <span className="text-blue-500">{val}</span>,
    },
    {
      title: 'Ngày đặt hàng',
      dataIndex: 'order_date',
      width: 150,
      render: (val) => val && moment(val).format(DATE_TIME_FORMAT),
    },
    {
      title: 'Loại đơn',
      dataIndex: 'type',
      width: 150,
      render: (val) => APPLY_TYPE[val],
    },
    {
      title: 'Giá khóa học',
      dataIndex: 'price',
      width: 150,
      render: (val) => formatCurrency(val ?? 0),
    },
    {
      title: 'Hoa hồng',
      dataIndex: 'commission',
      width: 150,
      render: (val) => formatCurrency(val ?? 0),
    },
    {
      title: 'Trạng thái đơn hàng',
      dataIndex: 'status',
      width: 150,
      render: (val) => (
        <Tag color={ORDER_STATUS_COLOR[val]}>{ORDER_STATUS[val]}</Tag>
      ),
    },
  ];

  const handleSearch = ({ keyword }) => {
    setParams((prev = {}) => ({
      ...prev,
      keyword,
      page: 1,
    }));
  };

  const handleFilter = (values) => {
    setParams((prev = {}) => ({
      ...prev,
      ...values,
      page: 1,
    }));
  };

  const handleChangePage: PaginationProps['onChange'] = (
    page: number,
    pageSize: number,
  ): void => {
    setParams((prev = {}) => ({
      ...prev,
      page,
      limit: pageSize,
    }));
  };

  return (
    <>
      <div className="bg-white py-[16px] rounded">
        <h4 className="font-medium text-blue-500 mb-4 border-b pb-2 px-[16px]">
          Danh sách đơn hàng
        </h4>
        <div className="flex gap-2.5 justify-end mb-2.5 px-[16px]">
          <Searching onSearch={handleSearch} />{' '}
          <Button
            type="alternative"
            className="rounded-xsm py-1 px-1 bg-blue-50"
            icon={
              <FunnelOutlined className=" text-blue-500 rounded-[4px] shrink-0" />
            }
            onClick={() => setOpenFilter(true)}
          />
        </div>
        <TableTera
          columns={columns}
          loading={isFetching}
          data={response?.data}
          pagination={{
            onChange: handleChangePage,
            to: response?.to,
            from: response?.from,
            current: response?.current_page,
            pageSize: response?.per_page,
            total: response?.total,
          }}
        />
      </div>
      {openFilter && (
        <FilterData
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={params}
        />
      )}
    </>
  );
};

export default Order;
