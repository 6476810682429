import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import TableTera from '_common/dof/TableTera';
import useConfirm from '_common/hooks/useConfirm';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import customTwMerge from 'tailwind-merge.config';
import {
  Badge,
  DropdownItem,
  formatCurrency,
  getQueryParams,
  Image,
  notification,
  PaginationProps,
  Tabs,
  Tag,
  updateQueryParams,
} from 'tera-dls';
import ProductApi from './api';
import ProductFilter from './containers/Filter';
import Searching from './containers/Searching';
import { PRODUCT_URL } from './url';
import { STATUS, STATUS_COLOR, UNIT_TYPE } from './constants';

const Product = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const {
    data: response,
    refetch,
    isFetching,
  } = useQuery(
    ['get-product-list', queryParams],
    () =>
      ProductApi.getList({
        page: 1,
        limit: 10,
        ...queryParams,
        ...(!!queryParams?.status &&
          queryParams?.status !== 'all' && { status: queryParams?.status }),
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { data: summary, refetch: refetchSummary } = useQuery(
    ['get-product-summary', queryParams],
    () =>
      ProductApi.getSummaryList({
        ...queryParams,
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
    refetchSummary();
  }, []);

  const handleUpdateQueryParams = (data: Record<string, any>): void => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const handleSearch = ({ keyword }: Record<string, any>): void => {
    handleUpdateQueryParams({ keyword, page: 1 });
  };

  const { mutate: mutateDelete } = useMutation(
    (variables: any) => ProductApi.delete(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-product-list']);
          queryClient.invalidateQueries(['get-product-summary']);

          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleDelete = (id: number, name: string): void => {
    confirm.warning({
      title: 'Xoá sản phẩm',
      content: (
        <>
          <p>Bạn có chắc muốn xóa sản phẩm</p>
          <p>
            <span className="font-bold break-word">{name}</span> này không?
          </p>
        </>
      ),
      onOk: () => {
        mutateDelete({ id });
      },
    });
  };

  const dropdownItemsAction = (record: any) => {
    const arrData: DropdownItem[] = [];
    arrData.push({
      key: 1,
      label: 'Xem',
      onClick: () => {
        navigate(`${PRODUCT_URL.detail.path}/${record?.id}`);
      },
    });
    arrData.push({
      key: 2,
      label: 'Sửa',
      onClick: () => {
        navigate(`${PRODUCT_URL.update.path}/${record?.id}`);
      },
    });
    record?.status == 'inactive' &&
      arrData.push({
        key: 3,
        label: <span className="text-red-500">Xoá</span>,
        onClick: () => handleDelete(record?.id, record?.name),
      });
    return arrData;
  };

  const handleFilter = (values: Record<string, any>): void => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const handleChangePage: PaginationProps['onChange'] = (
    page: number,
    pageSize: number,
  ): void => {
    handleUpdateQueryParams({
      page,
      limit: pageSize,
    });
  };

  const tabItems = useMemo(() => {
    const data = Object.keys(STATUS)?.map((key) => ({
      key: key,
      label: (
        <h3 className="tab-table">
          <span>{STATUS[key]}</span>
          <Badge
            showZero
            count={summary?.find((i) => i.status == key)?.total_count ?? 0}
          />
        </h3>
      ),
    }));
    return [
      {
        key: 'all',
        label: (
          <h3 className="tab-table">
            <span>{'Tất cả'}</span>
            <Badge
              showZero
              count={summary?.find((i) => i.status === 'all')?.total_count ?? 0}
            />
          </h3>
        ),
      },
      ...data,
    ];
  }, [summary]);

  const columns: any = [
    {
      title: 'SKU',
      dataIndex: 'sku',
      width: 150,
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
      width: 200,
      render: (val, record) => (
        <div className="flex gap-x-2 items-start">
          <div className="h-[35px]">
            <Image
              alt={''}
              imageClassName="object-cover"
              src={record?.image_url}
              containerClassName={customTwMerge(
                'size-[35px] rounded overflow-hidden',
              )}
            />
          </div>

          <div className="flex flex-col gap-1 ">
            <p className="text-[#1C64F2]">{val}</p>
          </div>
        </div>
      ),
    },
    {
      title: 'Danh mục sản phẩm',
      dataIndex: 'category',
      width: 150,
      render: (val) => val?.name,
    },
    {
      title: 'Đơn vị',
      dataIndex: 'unit_key',
      width: 150,
      render: (val) => UNIT_TYPE[val],
    },
    {
      title: 'Đơn giá',
      dataIndex: 'unit_price',
      width: 150,
      render: (val) => formatCurrency(val ?? 0),
    },
    {
      title: 'Giá khuyến mãi',
      dataIndex: 'promotional_price',
      width: 150,
      render: (val) => formatCurrency(val ?? 0),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 150,
      render: (val) => <Tag color={STATUS_COLOR[val]}>{STATUS[val]}</Tag>,
    },
    {
      title: '',
      width: 50,
      fixed: 'right',
      render: (record: any) => (
        <ActionDropdown
          dropdownItems={dropdownItemsAction(record)}
          trigger="click"
        />
      ),
    },
  ];

  const handleChangeTab = (key: any): void => {
    handleUpdateQueryParams({ status: key, page: 1 });
  };

  return (
    <div className="tera-page">
      <HeaderViewListV2
        title="Danh sách sản phẩm"
        onClickButtonAdd={() => navigate(PRODUCT_URL.create.path)}
        actionLeftRender={<Searching onSearch={handleSearch} />}
        onClickFilter={() => setOpenFilter(true)}
        middleContent={
          <Tabs
            onChange={handleChangeTab}
            items={tabItems}
            activeKey={queryParams?.status || 'all'}
            className="mb-0 pt-0 bg-white rounded-t-md"
          />
        }
        filterCount={{
          filter: queryParams,
          params: ['category_id'],
        }}
      >
        <TableTera
          rowKey="id"
          columns={columns}
          data={response?.data ?? []}
          loading={isFetching}
          pagination={{
            onChange: handleChangePage,
            total: response?.total || 0,
            current: response?.current_page,
            pageSize: response?.per_page,
            to: response?.to,
            from: response?.from,
          }}
        />
      </HeaderViewListV2>
      {openFilter && (
        <ProductFilter
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
    </div>
  );
};

export default Product;
