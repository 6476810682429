import { useQuery } from '@tanstack/react-query';
import { removeNullObject } from '_common/utils';
import _ from 'lodash';
import CourseOrderApi from 'pages/CourseManagement/CourseOrder/api';
import CourseOrderTable from 'pages/CourseManagement/CourseOrder/containers/CourseOrderTable';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getQueryParams, PaginationProps, updateQueryParams } from 'tera-dls';

const OrderTab = ({ classification, studentId }) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const params = _.pick(queryParams, ['lecture']);

  const paramsObject = params?.lecture ? JSON.parse(params?.lecture) : {};

  const {
    data: response,
    refetch,
    isFetching,
  } = useQuery(
    ['get-course-order-list', params, studentId, classification],
    () =>
      CourseOrderApi.getList({
        page: 1,
        limit: 10,
        classification,
        student_id: studentId,
        ...removeNullObject(paramsObject),
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    !!studentId && refetch();
  }, [studentId]);

  const handleUpdateQueryParams = (data: Record<string, any>): void => {
    const paramString = updateQueryParams({
      ...queryParams,
      lecture: JSON.stringify({
        ...paramsObject,
        ...data,
      }),
    });
    navigate(location.pathname + paramString);
  };

  const handleChangePage: PaginationProps['onChange'] = (
    page: number,
    pageSize: number,
  ): void => {
    handleUpdateQueryParams({
      page,
      limit: pageSize,
    });
  };

  return (
    <CourseOrderTable
      loading={isFetching}
      data={response?.data ?? []}
      pagination={{
        onChange: handleChangePage,
        total: response?.total || 0,
        current: response?.current_page,
        pageSize: response?.per_page || 10,
        to: response?.to,
        from: response?.from || 10,
      }}
      hiddenColumns={['classification']}
    />
  );
};

export default OrderTab;
