import Filter from '_common/component/Filter';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import DatePicker from '_common/dof/Control/DatePicker';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import _ from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface RefundFilterProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue: any;
}

function RefundFilter({
  open,
  onClose,
  onFilter,
  initialValue,
}: RefundFilterProps) {
  const form = useForm({ mode: 'onChange' });

  const handleSubmitForm = (values) => {
    const data = {
      ...values,
      date: values?.date
        ? moment(values?.date).format(DATE_BACKEND_FORMAT)
        : null,
    };
    onFilter(data);
    onClose();
  };

  const handleReset = () => {
    const values = {
      _type: null,
      date: null,
    };
    onFilter(values);
    onClose();
  };

  useEffect(() => {
    const values = _.pick(initialValue, ['type', 'date']);
    const data = {
      ...values,
      date: values?.date ? moment(values?.date, DATE_BACKEND_FORMAT) : null,
    };
    form.reset(data);
  }, [initialValue]);

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={form.handleSubmit(handleSubmitForm)}>
        <FormTeraItem label="Thời gian" name="date">
          <DatePicker />
        </FormTeraItem>
        <FormTeraItem label="Loại hóa đơn" name="type">
          <Select />
        </FormTeraItem>

        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default RefundFilter;
