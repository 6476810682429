import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import { IconButton } from '_common/component/TableColumnCustom';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import TableTera from '_common/dof/TableTera';
import useConfirm from '_common/hooks/useConfirm';
import { DefaultTag } from '_common/shared/utils';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftOutlined,
  Breadcrumb,
  DropdownItem,
  getQueryParams,
  notification,
  PaginationProps,
  updateQueryParams,
} from 'tera-dls';
import { IFormModel } from '../..';
import { USER_URL } from '../../url';
import UserAddressForm from './Form';
import Searching from './Searching';
import UserAddressDetail from './Detail';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import UserAddressApi from '../../api/address';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import UserApi from '../../api';

const UserAddress = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { id } = useParams();
  const queryParams = getQueryParams(search) as any;
  const confirm = useConfirm();
  const [openFormModel, setOpenFormModel] = useState<IFormModel>({
    open: false,
  });

  const [openDetailModel, setOpenDetailModel] = useState<IFormModel>({
    open: false,
  });
  const queryClient = useQueryClient();

  const {
    data: response,
    refetch,
    isFetching,
  } = useQuery(
    ['get-user-address-list', queryParams],
    () =>
      UserAddressApi.getList({
        page: 1,
        limit: 10,
        user_id: id,
        ...queryParams,
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { data: detail, refetch: fetchData } = useQuery(
    ['get-user-detail', id],
    () => UserApi.getDetail(id),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!id,
    },
  );

  useEffect(() => {
    id && fetchData();
  }, [id]);

  useEffect(() => {
    refetch();
  }, []);

  const handleUpdateQueryParams = (data: Record<string, any>): void => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const handleSearch = ({ keyword }: Record<string, any>): void => {
    handleUpdateQueryParams({ keyword, page: 1 });
  };

  const handleChangePage: PaginationProps['onChange'] = (
    page: number,
    pageSize: number,
  ): void => {
    handleUpdateQueryParams({
      page,
      limit: pageSize,
    });
  };

  const { mutate: mutateSetDefault } = useMutation(
    (variables: any) => UserAddressApi.setDefault(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-user-address-list']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleSetDefault = (id: number, name: string): void => {
    confirm.warning({
      title: 'Đặt làm địa chỉ mặc định',
      content: (
        <p className="break-word">
          <p>Bạn có chắc muốn đặt địa chỉ</p>
          <p>
            <span className="font-bold break-word">{` ${name ?? ''} `} </span>
            làm địa chỉ mặc định không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateSetDefault({ id });
      },
    });
  };

  const { mutate: mutateDelete } = useMutation(
    (variables: any) => UserAddressApi.delete(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-user-address-list']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleDelete = (id: number, name: string): void => {
    confirm.warning({
      title: 'Xóa địa chỉ giao hàng',
      content: (
        <p className="break-word">
          <p>Bạn có chắc muốn xóa địa chỉ giao hàng</p>
          <p>
            <span className="font-bold break-word">{` ${name ?? ''} `} </span>
            này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateDelete({ id });
      },
    });
  };

  const generateDropDownItems = (record): DropdownItem[] => {
    const dropdownItems: DropdownItem[] = [
      {
        key: 1,
        label: <a onClick={() => record}>Xem</a>,
        onClick: () => setOpenDetailModel({ open: true, value: record?.id }),
      },
      ...(!Boolean(record?.is_default)
        ? [
            {
              key: 3,
              label: <a>Đặt làm mặc định</a>,
              onClick: () => handleSetDefault(record?.id, record?.name),
            },
          ]
        : []),
      {
        key: 4,
        label: <a>Sửa</a>,
        onClick: () => setOpenFormModel({ open: true, value: record?.id }),
      },
      ...(!Boolean(record?.is_default)
        ? [
            {
              key: 5,
              label: <a className="text-red-500">Xóa</a>,
              onClick: () => handleDelete(record?.id, record?.name),
            },
          ]
        : []),
    ];

    return dropdownItems;
  };

  const columns: any = [
    {
      title: 'Họ và tên',
      dataIndex: 'full_name',
      width: 100,
      render: (val, record) => (
        <div className="flex gap-1 items-center">
          <span>{val}</span>
          {Boolean(record?.is_default) && <DefaultTag title="Mặc định" />}
        </div>
      ),
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      width: 100,
    },
    {
      title: 'Địa chỉ cụ thể',
      dataIndex: 'address',
      width: 150,
    },
    {
      title: 'Tỉnh/Thành phố',
      dataIndex: 'city_info',
      width: 100,
      render: (val) => val?.name,
    },
    {
      title: 'Quận/Huyện',
      dataIndex: 'district_info',
      width: 100,
      render: (val) => val?.name,
    },
    {
      title: 'Phường/Xã',
      width: 100,
      dataIndex: 'ward_info',
      render: (val) => val?.name,
    },
    {
      title: '',
      dataIndex: 'operation',
      width: 46,
      fixed: 'right',
      render: (_, record) => (
        <ActionDropdown
          dropdownItems={generateDropDownItems(record)}
          trigger="click"
          containerClassName="w-[50px]"
          placement="bottom-end"
        />
      ),
    },
  ];

  const BreadcrumbItem = [
    {
      title: (
        <a className="cursor-pointer text-blue-400 italic text-sm">
          Danh sách người dùng
        </a>
      ),
      onClick: () => navigate(USER_URL.list.path),
    },
    {
      title: (
        <a className="font-medium hover:text-gray-700 cursor-default tracking-[0.2px] text-sm">
          Danh sách địa chỉ người dùng "{detail?.full_name}"
        </a>
      ),
    },
  ];

  return (
    <>
      <div className="tera-page">
        <HeaderViewListV2
          titleRender={
            <div className="flex gap-1 items-center">
              <IconButton
                icon={<ArrowSmallLeftOutlined className="size-5" />}
                callBack={() => navigate(-1)}
              />
              <Breadcrumb
                containerItemClassName="text-sm"
                separator="/"
                items={BreadcrumbItem}
              />
            </div>
          }
          onClickButtonAdd={() => setOpenFormModel({ open: true })}
          actionLeftRender={<Searching onSearch={handleSearch} />}
        >
          <TableTera
            wrapperClassName="shadow-none"
            rowKey={'id'}
            loading={isFetching}
            className="center-table"
            data={response?.data ?? []}
            pagination={{
              onChange: handleChangePage,
              total: response?.total || 0,
              current: response?.current_page,
              pageSize: response?.per_page || 10,
              to: response?.to,
              from: response?.from || 10,
            }}
            columns={columns}
          />
        </HeaderViewListV2>
      </div>
      {openFormModel.open && (
        <UserAddressForm
          open={openFormModel.open}
          onClose={() => setOpenFormModel({ open: false })}
          value={openFormModel.value}
          userId={id}
        />
      )}
      {openDetailModel.open && (
        <UserAddressDetail
          open={openDetailModel.open}
          onClose={() => setOpenDetailModel({ open: false })}
          value={openDetailModel.value}
        />
      )}
    </>
  );
};

export default UserAddress;
