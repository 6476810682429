import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Col,
  formatCurrency,
  formatNumber,
  getQueryParams,
  Row,
} from 'tera-dls';
import RevenueApi from './api';

export interface IFormModel {
  open: boolean;
  value?: string | number;
}

const Revenue = () => {
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const params = _.omit(queryParams, ['status']);
  const { data: response, refetch } = useQuery(
    ['get-user-list', queryParams],
    () =>
      RevenueApi.getList({
        page: 1,
        limit: 10,
        ...params,
        ...(!!queryParams?.status &&
          queryParams?.status !== 'all' && { status: queryParams?.status }),
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );
  response;

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <div className="tera-page">
        <div className="text-gray-700 font-semibold text-base uppercase mb-[13px]">
          Báo cáo doanh thu
        </div>
        <div className="rounded-[10px] p-2.5 bg-white">
          <Row className="grid-cols-3 gap-2.5">
            <Col className="col-span-1 rounded-[10px] border border-gray-200 flex flex-col gap-[15px] p-2.5">
              <div className="text-[#6B7280] text-[13px] font-bold">
                Tổng doanh thu
              </div>
              <div className="text-[24px]">{formatCurrency(999000000000)}</div>
            </Col>
            <Col className="col-span-1 rounded-[10px] border flex flex-col gap-[15px] p-2.5">
              <div className="text-[#6B7280] text-[13px] font-bold">
                Giảng viên
              </div>
              <div className="text-[24px]">{formatNumber(999)}</div>
            </Col>
            <Col className="col-span-1 rounded-[10px] border flex flex-col gap-[15px] p-2.5">
              <div className="text-[#6B7280] text-[13px] font-bold">
                Loại khóa học
              </div>
              <div className="text-[24px]">{formatNumber(12)}</div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Revenue;
