import { Modal, Spin } from 'tera-dls';

import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import { useForm } from 'react-hook-form';
import InputNumber from '_common/dof/Control/InputNumber';
import VideoBackground from '_common/component/VideoBackground';

interface IProps {
  open: boolean;
  value?: string | number;
  onClose: () => void;
}

const StudentHomeWorkDetail = (props: IProps) => {
  const { open, value: id, onClose } = props;
  id;

  const form = useForm();
  const {
    handleSubmit,
    formState: { isDirty },
  } = form;

  const confirm = useConfirm();

  const handleCancel = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>Bạn có chắc muốn thoát bản ghi này không?</p>
            <p>Sau khi thoát dữ liệu sẽ không được lưu.</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
      return;
    }
    onClose();
  };

  const handleSubmitForm = (values) => {
    values;
  };
  // const {
  //   data: detail,
  //   refetch: fetchData,
  //   isLoading,
  // } = useQuery(['get-user-detail', id], () => UserApi.getDetail(id), {
  //   staleTime: 300000,
  //   cacheTime: 300000,
  //   enabled: !!id,
  // });

  // useEffect(() => {
  //   id && fetchData();
  // }, [id]);

  return (
    <>
      <Modal
        open={open}
        title={'Chi tiết nộp bài'}
        closeIcon={false}
        maskClosable={false}
        width={600}
        onCancel={handleCancel}
        onOk={handleSubmit(handleSubmitForm)}
        okText="Đồng ý"
        cancelText="Hủy"
      >
        <Spin spinning={false}>
          <FormTera form={form} onSubmit={handleSubmitForm}>
            <FormTeraItem label="Chấm điểm" name="score">
              <InputNumber min={0} max={10} />
            </FormTeraItem>
            <FormTeraItem label="Nội dung nộp" name="">
              <div className="flex gap-2.5 items-center">
                <VideoBackground
                  value={{
                    url: 'https://bake-api.teravn.com/assets/upload/lecture-item/1726456051_download-2.mp4',
                  }}
                  isView
                  type={'uploaded_video'}
                />
                <VideoBackground
                  value={{
                    url: 'https://youtu.be/Bhg-Gw953b0?si=TGZqwhlF8O74Sj3q',
                  }}
                  isView
                  type={'youtube_link'}
                />
                <VideoBackground
                  value={{
                    url: 'https://drive.google.com/file/d/1ZgHHHyQeSGqjDKtbDQ4m8dzewByRr3b8/view?usp=drive_link',
                  }}
                  isView
                  type={'gg_drive_link'}
                />
              </div>
            </FormTeraItem>
          </FormTera>
        </Spin>
      </Modal>
    </>
  );
};

export default StudentHomeWorkDetail;
