import { useQuery } from '@tanstack/react-query';
import CardDetail from '_common/component/CardDetail';
import HoverQuickView from '_common/component/HoverQuickView';
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  TIME_FORMAT,
} from '_common/constants/common';
import { renderDescription } from '_common/utils/utils';
import moment from 'moment';
import {
  COURSE_METHOD,
  COURSE_METHOD_COLOR,
  COURSE_TYPE,
} from 'pages/CourseManagement/Course/constants';
import { COURSE_URL } from 'pages/CourseManagement/Course/url';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import customTwMerge from 'tailwind-merge.config';
import { Button, Col, formatCurrency, Modal, Row, Spin, Tag } from 'tera-dls';
import CourseOrderApi from '../../api';
import {
  COURSE_ORDER_STATUS,
  COURSE_ORDER_STATUS_COLOR,
} from '../../constants';

interface IProps {
  open: boolean;
  value?: string | number;
  onClose: () => void;
}

const CourseOrderDetail = (props: IProps) => {
  const { open, value: id, onClose } = props;
  const navigate = useNavigate();

  const {
    data: detail,
    refetch: fetchData,
    isLoading,
  } = useQuery(
    ['get-course-order-detail', id],
    () => CourseOrderApi.getDetail(id),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!id,
    },
  );

  useEffect(() => {
    id && fetchData();
  }, [id]);
  detail;

  const data = [
    {
      key: '1',
      title: 'Họ và tên',
      value: (
        <HoverQuickView
          name={detail?.user?.full_name}
          avatarUrl={detail?.user?.avatar}
          phone={detail?.user?.phone}
          email={detail?.user?.email}
        >
          <span className="text-blue-600"> {detail?.user?.full_name}</span>
        </HoverQuickView>
      ),
    },
    {
      key: '2',
      title: 'Khóa học',
      value: (
        <span
          className="line-clamp-2  text-blue-600 cursor-pointer inline"
          onClick={() =>
            !!detail?.course?.id &&
            navigate(`${COURSE_URL.detail.path}/${detail?.course?.id}`)
          }
        >
          {detail?.course?.name}
        </span>
      ),
    },
    {
      key: '3',
      title: 'Giảng viên',
      value: (
        <HoverQuickView
          name={detail?.lecturer?.full_name}
          avatarUrl={detail?.lecturer?.avatar}
          phone={detail?.lecturer?.phone}
          email={detail?.lecturer?.email}
        >
          <span className="text-blue-600"> {detail?.lecturer?.full_name}</span>
        </HoverQuickView>
      ),
    },
    {
      key: '4',
      title: 'Danh mục khóa học',
      value: detail?.course_category?.name,
    },
    {
      key: '5',
      title: 'Loại khóa học',
      value: COURSE_TYPE[detail?.type],
    },
    {
      key: '6',
      title: 'Loại đơn',
      value: (
        <span className={`${COURSE_METHOD_COLOR[detail?.classification]}`}>
          {COURSE_METHOD[detail?.classification]}
        </span>
      ),
    },
    {
      key: '7',
      title: 'Trạng thái',
      value: (
        <Tag color={COURSE_ORDER_STATUS_COLOR[detail?.status]}>
          {COURSE_ORDER_STATUS[detail?.status]}
        </Tag>
      ),
    },
    {
      key: '8',
      title: 'Lý do hủy',
      value: detail?.reason_cancel,
    },
    // {
    //   key: '9',
    //   title: 'Thời gian khuyến mãi',
    //   value:
    //     detail?.course?.discount_time?.[0] &&
    //     `${moment(detail?.course?.discount_time?.[0]).format(
    //       DATE_FORMAT,
    //     )} - ${moment(detail?.course?.discount_time?.[1]).format(DATE_FORMAT)}`,
    // },
    {
      key: '10',
      title: 'Mã giới thiệu',
      value: detail?.referral_code,
    },
  ];
  const data1 = [
    {
      key: '1',
      title: `Giá khóa học ${COURSE_METHOD[detail?.classification]}`,
      value: formatCurrency(detail?.price ?? 0),
    },
    // {
    //   key: '2',
    //   title: `Giá khuyến mãi`,
    //   value: formatCurrency(detail?.course?.discount_price ?? 0),
    // },
    {
      key: '3',
      title: `Mã giảm giá`,
      value: detail?.discount_code,
    },
    {
      key: '4',
      title: `Thành tiền`,
      value: (
        <span className="text-green-500">{formatCurrency(detail?.total)}</span>
      ),
    },
  ];

  const data2 = [
    {
      key: '1',
      title: `Người tạo`,
      value: (
        <HoverQuickView
          name={detail?.user_created?.full_name}
          avatarUrl={detail?.user_created?.avatar}
        >
          <span className="line-clamp-1 text-blue-600">
            {detail?.user_created?.full_name}
          </span>
        </HoverQuickView>
      ),
    },
    {
      key: '2',
      title: 'Ngày tạo',
      value: moment(detail?.created_at).format(DATE_TIME_FORMAT),
    },
    ...(detail?.user_updated
      ? [
          {
            key: '3',
            title: 'Người cập nhật',
            value: (
              <HoverQuickView
                name={detail?.user_updated?.full_name}
                avatarUrl={detail?.user_updated?.avatar}
              >
                <span className="line-clamp-1 text-blue-600">
                  {detail?.user_updated?.full_name}
                </span>
              </HoverQuickView>
            ),
          },

          {
            key: '2',
            title: 'Ngày cập nhật',
            value: moment(detail?.updated_at).format(DATE_TIME_FORMAT),
          },
        ]
      : []),
  ];

  return (
    <>
      <Modal
        open={open}
        title={`Chi tiết đơn khóa học ${COURSE_METHOD[detail?.classification]}`}
        closeIcon={false}
        width={detail?.classification == 'online' ? 1000 : 500}
        maskClosable={false}
        footer={<Button onClick={onClose}>Đóng</Button>}
      >
        <Spin spinning={false || isLoading}>
          <Row
            className={customTwMerge(
              'grid-cols-1 gap-2.5',
              detail?.classification == 'online' && 'grid-cols-2',
            )}
          >
            <Col>
              <CardDetail className="!shadow-none" title="Thông tin chung">
                <div className="grid gap-2.5 mb-2">
                  {renderDescription(data)}
                </div>
                <div className=" grid gap-2.5 mb-2">
                  {renderDescription(data1)}
                </div>
                <div className="grid gap-2.5">{renderDescription(data2)}</div>
              </CardDetail>
            </Col>
            {detail?.classification == 'online' && (
              <Col>
                <CardDetail title="Thời gian học" className="!shadow-none">
                  <div className="divide-y-[1px] pl-2">
                    {detail?.schedules && (
                      <div className="flex gap-[70px]">
                        <span>
                          {moment(detail?.schedules?.date).format(DATE_FORMAT)}
                        </span>
                        <span className="text-green-500">
                          {moment(
                            detail?.schedules?.start_time,
                            TIME_FORMAT,
                          ).format(TIME_FORMAT)}{' '}
                          -{' '}
                          {moment(
                            detail?.schedules?.end_time,
                            TIME_FORMAT,
                          ).format(TIME_FORMAT)}
                        </span>
                      </div>
                    )}
                  </div>
                </CardDetail>
              </Col>
            )}
          </Row>
        </Spin>
      </Modal>
    </>
  );
};

export default CourseOrderDetail;
