import { useQuery } from '@tanstack/react-query';
import TableTera from '_common/dof/TableTera';
import { IPagination } from '_common/interface';
import { useContext, useState } from 'react';
import { formatCurrency, PaginationProps } from 'tera-dls';
import { StatisticCashExpenseByInvestorParamContext } from '.';
import StatisticApi from '../../api';
import Header from '../Header';
import ModalChart from './ModalChart';
import SettingModal from './SettingModal';

interface ContentProps {
  type?: string | boolean;
  paginationDefault?: IPagination;
  onClose?: () => void;
}

function Content({
  paginationDefault = {
    page: 1,
    limit: 10,
  },
  type = 'small',
  onClose,
}: ContentProps) {
  const [openSetting, setOpenSetting] = useState<boolean>(false);
  const [openZoom, setOpenZoom] = useState<boolean>(false);
  const [pagination, setPagination] = useState<IPagination>(paginationDefault);

  const { contextParams, setContextParams } = useContext(
    StatisticCashExpenseByInvestorParamContext,
  );

  const {
    data: orderList,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery(
    ['get-statistic-by-transaction-type', contextParams, pagination],
    () =>
      StatisticApi.getList({
        params: {
          ...pagination,
          ...contextParams,
          chart_type: 'byTransactionType',
          receipt_transaction_type:
            contextParams?.receipt_transaction_type?.join(','),
          payment_transaction_type:
            contextParams?.payment_transaction_type?.join(','),
          date_from: contextParams?.date[0],
          date_to: contextParams?.date[1],
          accounting_date_from: contextParams?.accounting_date[0],
          accounting_date_to: contextParams?.accounting_date[1],
          payment_method: contextParams?.payment_method?.join(','),
          date: undefined,
          accounting_date: undefined,
        },
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const columns: any = [
    {
      title: 'Tên học viên',
      dataIndex: 'student',
      render: (val) => val?.full_name,
    },
    {
      title: 'Thu',
      dataIndex: 'receipt',
      render: (text) => formatCurrency(text),
    },
    {
      title: 'Chi',
      dataIndex: 'payment',
      render: (text) => formatCurrency(text),
    },
  ];

  const isSmallType = type === 'small';

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize = Number(pageSize) !== Number(pagination?.limit || 10);
    setPagination({ page: isDiffPageSize ? 1 : page, limit: pageSize });
  };

  return (
    <div className="rounded-[5px] border-[1px] bg-white h-full flex flex-col overflow-hidden ">
      <Header
        title="Thu chi theo học viên"
        isSmallType={isSmallType}
        onClickRefresh={refetch}
        onZoomOut={() => setOpenZoom(true)}
        onZoomIn={() => onClose()}
        onClickConfig={() => setOpenSetting(true)}
      />
      <div className="p-4">
        <TableTera
          rowKey="id"
          columns={columns}
          data={orderList?.data || []}
          pagination={{
            onChange: handleChangePage,
            total: orderList?.total || 0,
            current: orderList?.current_page,
            pageSize: orderList?.per_page || 10,
            to: orderList?.to,
            from: orderList?.from || 10,
          }}
          loading={isRefetching || isLoading}
        />
      </div>

      {openSetting && (
        <SettingModal
          open={openSetting}
          onClose={() => setOpenSetting(false)}
          onSubmit={(value) => setContextParams(value)}
        />
      )}

      {openZoom && (
        <ModalChart
          open={openZoom}
          onClose={() => setOpenZoom(false)}
          type="large"
          paginationDefault={pagination}
        />
      )}
    </div>
  );
}

export default Content;
