import { useQuery } from '@tanstack/react-query';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import { removeNullObject } from '_common/utils';
import WithdrawalRequestApi from 'pages/Affiliate/WithdrawalRequest/api';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ReactComponent as IconRecharge } from 'styles/images/wallet/recharge.svg';
import { ReactComponent as IconWithdrawal } from 'styles/images/wallet/withdrawal.svg';
import { formatCurrency, formatDate, PaginationProps, Tag } from 'tera-dls';

const RenderTransactionType = ({ type }) => {
  const objectType = {
    receive: { name: 'Nạp tiền', icon: <IconRecharge /> },
    withdrawal: { name: 'Rút tiền', icon: <IconWithdrawal /> },
  };

  return (
    <div className="flex items-center gap-x-2">
      {objectType[type]?.icon}
      <span> {objectType[type]?.name}</span>
    </div>
  );
};

const TransactionHistory = () => {
  const [params, setParams] = useState<any>({});
  const { id } = useParams();
  const {
    data: response,
    refetch: fetchData,
    isFetching,
  } = useQuery(
    ['get-affiliate-overview-transaction-history', id, params],
    () =>
      WithdrawalRequestApi.getList({
        at_detail_affiliate: id,
        ...removeNullObject(params),
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!id,
    },
  );

  useEffect(() => {
    id && fetchData();
  }, [id]);

  const columns: any = [
    {
      title: 'Mã giao dịch',
      dataIndex: 'code',
      width: 150,
      render: (val) => <span className="text-blue-500">{val}</span>,
    },

    {
      title: 'Loại giao dịch',
      dataIndex: 'type',
      width: 150,
      render: (type) => <RenderTransactionType type={type} />,
    },

    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 150,
      render: (status) =>
        status && (
          <Tag color={'green03'} className="font-medium">
            Thành công
          </Tag>
        ),
    },
    {
      title: 'Số tiền',
      dataIndex: 'money',
      width: 150,
      render: (amount) => amount && formatCurrency(amount),
    },
    {
      title: 'Thời gian tạo',
      dataIndex: 'created_at',
      width: 150,
      render: (created_at) =>
        created_at && formatDate(created_at, DATE_TIME_FORMAT),
    },
  ];

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    setParams((prev = {}) => ({ ...prev, page, limit: pageSize }));
  };

  return (
    <div className="bg-white py-[16px] rounded">
      <h4 className="font-medium text-blue-500 mb-4 border-b pb-2 px-[16px]">
        Lịch sử giao dịch
      </h4>
      <div className="px-[16px]">
        <TableTera
          columns={columns}
          data={response?.data ?? []}
          loading={isFetching}
          pagination={{
            onChange: handleChangePage,
            to: response?.to,
            from: response?.from,
            current: response?.current_page,
            pageSize: response?.per_page,
            total: response?.total,
          }}
        />
      </div>
    </div>
  );
};

export default TransactionHistory;
