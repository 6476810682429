import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const RevenueEndpoint = `${endpoint}/bake-teach/report/revenue`;
const RevenueApi = {
  getList: async (params) =>
    await api
      .get(`${RevenueEndpoint}/list`, params)
      .then((data) => data?.data?.data),
};
export default RevenueApi;
