export const TYPE_INVOICE = {
  refund: 'Hoàn tiền',
  course_commission: 'Hoa hồng khóa học',
  payment: 'Thanh toán',
  deposit: 'Nạp tiền',
  withdraw: 'Rút tiền',
  affiliate_commission: 'Hoa hồng Affiliate',
};

export const METHOD_INVOICE = {
  wallet: 'Ví của tôi',
  transfer: 'Chuyển khoản',
  payment_upon_receipt: 'Thanh toán khi nhận hàng',
};
export const STATUS_INVOICE = {
  pending: 'Đang xử lý',
  approved: 'Hoàn thành',
  fail: 'Thất bại',
  error: 'Báo lỗi',
  paid: 'Đã thanh toán',
  not_yet_paid: 'Chưa thanh toán',
};

export const STATUS_INVOICE_COLOR = {
  pending: 'yellow03',
  approved: 'green03',
  fail: 'gray01',
  error: 'red03',
  paid: 'blue03',
  not_yet_paid: 'yellow03',
};

export const STATUS_INVOICE_APPROVED = 'approved';

export const INVOICE_METHOD = {
  transfer: 'Chuyển khoản',
  wallet: 'Ví của tôi',
  payment_upon_receipt: 'Thanh toán khi nhận hàng',
};

export const INVOICE_DETAIL_TAB_KEY = {
  information: 'Thông tin chi tiết',
  'transaction-history': 'Lịch sử giao dịch',
  'cash-receipt': 'Phiếu thu',
  'expense-voucher': 'Phiếu chi',
};
