import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ButtonCommon from '_common/component/Button';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftSolid,
  Badge,
  Breadcrumb,
  Button,
  Dropdown,
  EllipsisHorizontalOutlined,
  getQueryParams,
  notification,
  PencilSquareOutlined,
  Spin,
  TabItemType,
  Tabs,
  updateQueryParams,
  XMarkOutlined,
} from 'tera-dls';

import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import useConfirm from '_common/hooks/useConfirm';
import { useEffect } from 'react';
import StudentApi from '../../api';
import { STUDENT_URL } from '../../url';
import Online from './Online';
import Overview from './Overview';

const StudentDetail = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const { id } = useParams();
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const handleUpdateQueryParams = (data: Record<string, any>): void => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const {
    data: dataDetail,
    isFetching,
    refetch,
  } = useQuery(['get-student-detail', id], () => StudentApi.getDetail(id), {
    staleTime: 300000,
    cacheTime: 300000,
    enabled: !!id,
  });

  const { data: summary, refetch: refetchSummary } = useQuery(
    ['get-student-detail-summary', id],
    () =>
      StudentApi.getSummaryDetail({
        student_id: id,
      }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (!!id) {
      refetch();
      refetchSummary();
    }
  }, [id]);
  console.log(summary);

  const tabItems: TabItemType[] = [
    {
      key: 'info',
      label: <span>Thông tin chung</span>,
    },
    {
      key: 'online',
      label: (
        <h3 className="flex gap-2.5">
          <span>Đơn mua khóa học online</span>
          <Badge showZero count={summary?.count_course_online ?? 0} />
        </h3>
      ),
    },
    {
      key: 'offline',
      label: (
        <h3 className="flex gap-2.5">
          <span>Đơn mua khóa học offline</span>
          <Badge showZero count={summary?.count_course_offline ?? 0} />
        </h3>
      ),
    },
  ];

  const handleChangeTabs = (currentTab: string) => {
    handleUpdateQueryParams({ tab: currentTab });
  };

  const nodeContent = {
    info: <Overview dataDetail={dataDetail} />,
    online: <Online id={dataDetail?.id} classification="online" />,
    offline: <Online id={dataDetail?.id} classification="offline" />,
  };

  const { mutate: mutateDelete } = useMutation(
    (variables: any) => StudentApi.delete(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-student-list']);
          queryClient.invalidateQueries(['get-student-summary']);
          notification.success({
            message: res?.msg,
          });
          navigate(STUDENT_URL.list.path);
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleDelete = (id: number, name: string): void => {
    confirm.warning({
      title: 'Xóa học viên',
      content: (
        <p className="break-word">
          <p>Bạn có chắc muốn xóa học viên</p>
          <p>
            <span className="font-bold break-word">{` ${name ?? ''} `} </span>
            này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateDelete({ id });
      },
    });
  };

  const dropdownItems = [
    ...(dataDetail?.is_active === 0
      ? [
          {
            key: 7,
            label: <a className="text-red-500">Xóa</a>,
            onClick: () => handleDelete(dataDetail?.id, dataDetail?.full_name),
          },
        ]
      : []),
    {
      key: '4',
      label: 'Sửa',
      onClick: () => navigate(`${STUDENT_URL.update.path}/${dataDetail?.id}`),
    },
  ];

  const buttonAction = (
    <>
      <div className={'hidden xl:flex xl:gap-x-2.5'}>
        <ButtonCommon
          icon={<PencilSquareOutlined className="w-4 h-4" />}
          onClick={() =>
            navigate(`${STUDENT_URL.update.path}/${dataDetail?.id}`)
          }
          className="page-header-btn"
        >
          Sửa
        </ButtonCommon>
        {dataDetail?.is_active === 0 && (
          <ButtonCommon
            icon={<XMarkOutlined className="w-4 h-4" />}
            onClick={() => handleDelete(dataDetail?.id, dataDetail?.name)}
            type="danger"
            className="page-header-btn"
          >
            Xóa
          </ButtonCommon>
        )}
      </div>
      <div className={'xl:hidden'}>
        <Dropdown menu={{ items: dropdownItems }} trigger="click">
          <Button
            icon={<EllipsisHorizontalOutlined />}
            type="alternative"
            className="page-header-btn"
          />
        </Dropdown>
      </div>
    </>
  );

  return (
    <Spin spinning={isFetching}>
      <div className="tera-page-form !gap-0">
        <div className="page-header-sticky bg-[#F3F3F9]">
          <div className="page-header-v2">
            <div className="page-header-v2__breadcrumb">
              <div
                className="page-header__breadcrumb-back cursor-pointer"
                onClick={() => navigate(-1)}
              >
                <ArrowSmallLeftSolid className="h-6 w-6" />
              </div>
              <Breadcrumb
                separator={'/'}
                containerItemClassName="text-sm"
                items={[
                  {
                    title: (
                      <a className="text-blue-400 hover:text-blue-600">
                        Danh sách học viên
                      </a>
                    ),
                    onClick: () => navigate(STUDENT_URL.list.path),
                  },
                  {
                    title: 'Chi tiết học viên',
                  },
                ]}
              />
            </div>
            <div className="page-header-v2__function">{buttonAction}</div>
          </div>
        </div>
        <div className=" page-content-v2 bg-white shadow-xsm rounded-md px-2.5 h-full mx-2.5 !mb-2.5 !gap-y-0">
          <Tabs
            onChange={handleChangeTabs}
            items={tabItems}
            activeKey={queryParams?.tab ?? 'info'}
          />
          <div>{nodeContent[queryParams?.tab ?? 'info']}</div>
        </div>
      </div>
    </Spin>
  );
};

export default StudentDetail;
