export const WITHDRAWAL_REQUEST_STATUS = {
  approve: 'Đã duyệt',
  pending: 'Chờ duyệt',
  system_reject: 'Hủy bởi hệ thống',
  buyer_reject: 'Hủy bởi người mua',
};

export const WITHDRAWAL_REQUEST_STATUS_COLOR = {
  approve: 'green03',
  pending: 'yellow03',
  system_reject: 'red03',
  buyer_reject: 'gray02',
};

export const TRANSACTION_STATUS = {
  1: 'Hoàn thành',
  2: 'Thất bại',
  3: 'Đang xử lý',
  4: 'Báo lỗi',
};

export const TRANSACTION_STATUS_COLOR = {
  1: 'green03',
  2: 'gray01',
  3: 'yellow03',
  4: 'red03',
};

export const ORDER_STATUS = {
  awaiting_payment: 'Đang chờ hoàn thành',
  completed: 'Hoàn thành',
  canceled: 'Đã hủy',
};

export const ORDER_STATUS_COLOR = {
  awaiting_payment: 'yellow03',
  completed: 'green03',
  canceled: 'gray02',
};
