import NoData from '_common/component/NoData';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import classNames from 'classnames';
import {
  Col,
  formatCurrency,
  formatDate,
  Image,
  Row,
  Spin,
  Tag,
} from 'tera-dls';
import {
  METHOD_INVOICE,
  STATUS_INVOICE,
  STATUS_INVOICE_COLOR,
  TYPE_INVOICE,
} from '../../constants';
import { UNIT_TYPE } from 'pages/SaleManagement/Product/constants';
import customTwMerge from 'tailwind-merge.config';
import TableTera from '_common/dof/TableTera';

function Information({ dataDetails, isLoading, isError }) {
  const detail = [
    {
      label: 'Mã hóa đơn',
      value: dataDetails?.invoice_code,
    },
    {
      label: 'Ngày thanh toán',
      value:
        dataDetails?.updated_at &&
        formatDate(dataDetails?.updated_at, DATE_TIME_FORMAT),
    },
    {
      label: 'Loại giao dịch',
      value: TYPE_INVOICE[dataDetails?.transaction_type],
    },
    {
      label: 'Ghi chú',
      value: dataDetails?.status_note,
    },
    {
      label: 'Trạng thái',
      value: dataDetails?.status && (
        <Tag color={STATUS_INVOICE_COLOR[dataDetails?.status] as any}>
          {STATUS_INVOICE[dataDetails?.status]}
        </Tag>
      ),
    },
  ];

  const user = [
    {
      label: 'Mã người dùng',
      value: dataDetails?.user?.code,
    },
    {
      label: 'Họ và tên',
      value: dataDetails?.user?.full_name,
    },
    {
      label: 'Số điện thoại',
      value: dataDetails?.user?.phone,
    },
    {
      label: 'Email liên hệ',
      value: dataDetails?.user?.email,
    },
  ];
  const course = [
    {
      label: 'Mã đơn',
      value: '',
    },
    {
      label: 'Họ và tên',
      value: '',
    },
    {
      label: 'Tên khóa học',
      value: '',
    },
    {
      label: 'Giảng viên',
      value: '',
    },
    {
      label: 'Danh mục khóa học',
      value: '',
    },
    {
      label: 'Phân loại khóa học',
      value: '',
    },
    {
      label: 'Loại khóa học',
      value: '',
    },
    {
      label: 'Giảng viên',
      value: '',
    },
  ];

  const columns: any = [
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
      width: 150,
      render: (val, record) => (
        <div className="flex gap-x-2 items-start">
          <div className="h-[35px]">
            <Image
              alt={record?.image_product}
              imageClassName="object-cover"
              src={record?.image_product}
              containerClassName={customTwMerge(
                'size-[35px] rounded overflow-hidden',
              )}
            />
          </div>

          <div className="flex flex-col gap-1 ">
            <p className="text-[#1C64F2]">{record?.name_product ?? val}</p>
          </div>
        </div>
      ),
    },
    {
      title: 'Danh mục sản phẩm',
      dataIndex: 'name_category',
      width: 120,
    },
    {
      title: 'Đơn vị',
      dataIndex: 'unit_key',
      width: 120,
      render: (val) => UNIT_TYPE[val],
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      width: 120,
    },
    {
      title: 'Đơn giá',
      dataIndex: 'price',
      width: 150,
      render: (price) => formatCurrency(Number(price)),
    },
    // {
    //   title: 'Giá khuyến mãi',
    //   dataIndex: 'promotional_price',
    //   width: 150,
    //   render: (total) => formatCurrency(Number(total)),
    // },
    {
      title: 'Thành tiền',
      dataIndex: 'total',
      width: 150,
      render: (total) => formatCurrency(Number(total)),
    },
  ];

  return (
    <Spin spinning={isLoading}>
      {isError ? (
        <NoData />
      ) : (
        <div className="grid grid-cols-12 gap-6">
          <div
            className={classNames(
              'col-span-8 p-8 rounded-md bg-[#FAFAF9] flex flex-col gap-y-6 shadow-bake',
            )}
          >
            <h2 className="text-[#11181C] font-bold	text-[22px]">Hóa đơn</h2>
            <div className="grid grid-cols-2 gap-2.5">
              {detail.map((item, index) => (
                <div className="flex items-start" key={index}>
                  <div className="detail-key">{item.label}</div>
                  <div className="detail-value">{item.value}</div>
                </div>
              ))}
            </div>
            <Row className="grid-cols-2">
              <Col>
                <p className="text-blue-500 mb-2.5">Thông tin nhà đầu tư</p>
                <div className="flex flex-col gap-2.5">
                  {user.map((item, index) => (
                    <div className="flex items-start" key={index}>
                      <div className="detail-key">{item.label}</div>
                      <div className="detail-value">{item.value}</div>
                    </div>
                  ))}
                </div>
              </Col>
              <Col>
                <p className="text-blue-500 mb-2.5">Thông tin khóa học</p>
                <div className="flex flex-col gap-2.5">
                  {course.map((item, index) => (
                    <div className="flex items-start" key={index}>
                      <div className="detail-key">{item.label}</div>
                      <div className="detail-value">{item.value}</div>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </div>

          <div className="col-span-4 flex flex-col gap-y-6 text-base">
            <div
              className={classNames(
                'p-4 rounded-md flex flex-col gap-y-8 bg-[#FAFAF9] shadow-bake',
              )}
            >
              <h3 className="text-[#252F4A] font-semibold">Thanh toán</h3>
              <div className="flex flex-col gap-y-2.5">
                <div className="flex justify-between">
                  <div className="shrink-0">Tạm tính</div>
                  <div className="text-[20px] font-medium text-green-600">
                    chưa có
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="shrink-0">Mã giới thiệu</div>
                  <div className="text-[20px] font-medium text-green-600">
                    chưa có
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="shrink-0">Mã giảm giá</div>
                  <div className="text-[20px] font-medium text-green-600">
                    chưa có
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="shrink-0">Tổng thanh toán</div>
                  <div className="text-[20px] font-medium text-green-600">
                    {formatCurrency(dataDetails?.amount)}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={classNames(
                'p-4 rounded-md flex flex-col gap-y-8 bg-[#FAFAF9] shadow-bake',
              )}
            >
              <h3 className="text-[#252F4A] font-semibold">
                Thông tin thanh toán
              </h3>
              <div className="flex justify-between">
                <div className="shrink-0">Phương tức thanh toán</div>
                <div className="font-medium text-gray-800">
                  {METHOD_INVOICE[dataDetails?.methods]}
                </div>
              </div>
            </div>
          </div>

          <div
            className={classNames(
              'col-span-8 p-8 rounded-md bg-[#FAFAF9] flex flex-col gap-y-6 shadow-bake',
            )}
          >
            <h2 className="text-[#11181C] font-bold	text-[22px]">Sản phẩm</h2>
            <div className="flex items-start">
              <div className="detail-key">Mã tham chiếu</div>
              <div className="detail-value">DH123</div>
            </div>
            <TableTera columns={columns} data={dataDetails?.products || []} />
          </div>
        </div>
      )}
    </Spin>
  );
}

export default Information;
