import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { messageValidate, messageWarning } from '_common/constants/message';
import CkeditorForm from '_common/dof/Control/CkeditorForm';
import Input from '_common/dof/Control/Input';
import Radio from '_common/dof/Control/Radio';
import VideoForm from '_common/dof/Control/VideoForm';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import CourseLessonApi from 'pages/CourseManagement/Course/api/Lesson';
import { LINK_COURSE_TYPE } from 'pages/CourseManagement/Course/constants';
import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, notification } from 'tera-dls';

interface IProps {
  open: boolean;
  value?: any;
  onClose: () => void;
  onSubmit?: (values) => void;
  mode?: any;
  sectionId?: number;
  courseId?: number;
}
export const renderTypeFile = {
  uploaded_video: <VideoForm />,
  youtube_link: (
    <Input placeholder="Vui lòng nhập link của youtube" maxLength={500} />
  ),
  gg_drive_link: (
    <Input placeholder="Vui lòng nhập link của google drive" maxLength={500} />
  ),
};

const LectureItemForm = (props: IProps) => {
  const {
    open,
    value,
    onClose,
    onSubmit,
    mode = 'create',
    sectionId,
    courseId,
  } = props;
  const form = useForm({ mode: 'onChange' });
  const {
    handleSubmit,
    formState: { isDirty },
  } = form;
  const ref = useRef(null);
  const queryClient = useQueryClient();
  const confirm = useConfirm();

  const typeFile = form.watch('link_url_type');

  const { data: detail, refetch: fetchData } = useQuery(
    ['get-course-lesson-detail', value?.id],
    () => CourseLessonApi.getDetail(value?.id),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!value?.id && mode == 'update',
    },
  );

  useEffect(() => {
    value?.id && mode == 'update' && fetchData();
  }, [value?.id, mode]);

  const { mutate: mutateAction, isLoading } = useMutation(
    (variables: any) =>
      value?.id
        ? CourseLessonApi.update(variables)
        : CourseLessonApi.create(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-course-lesson-list']);
          notification.success({
            message: res?.msg,
          });
          onClose();
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  useEffect(() => {
    ref.current && ref.current.focus();
  }, [ref]);

  useEffect(() => {
    if (!!detail || !!value?.id) {
      form.reset({
        name: detail?.name ?? value?.name,
        content: detail?.content ?? value?.content,
        link_url_type: detail?.link_url_type ?? value?.link_url_type,
        link_url: detail?.link_url?.url ?? value?.link_url?.url,
      });
    } else {
      form.setValue('link_url_type', 'uploaded_video');
    }
  }, [value, detail]);

  const handleCancel = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <p className="break-word">
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </p>
        ),
        onOk: () => {
          onClose();
        },
      });
      return;
    }
    onClose();
  };

  const handleSubmitForm = (values) => {
    if (mode == 'update') {
      mutateAction({
        params: {
          ...values,
          link_url: {
            thumbnail: form.getValues('thumbnail'),
            url: values?.link_url,
            duration: form.getValues('updated_video_duration'),
          },
          section_id: sectionId,
          course_id: courseId,
        },
        ...(value?.id && { id: value?.id }),
      });
      return;
    }
    onSubmit({
      ...(value ?? {}),
      ...values,
      link_url: { url: values?.link_url },
    });
    onClose();
  };

  const typeOptions = Object.entries(LINK_COURSE_TYPE).map(
    ([value, label]) => ({
      value,
      label,
    }),
  );
  const getRules = () => {
    if (typeFile === 'youtube_link') {
      return {
        pattern: {
          value: new RegExp('.*youtu.*'),
          message: 'Vui lòng nhập link Youtube',
        },
      };
    }
    if (typeFile === 'gg_drive_link') {
      return {
        pattern: {
          value: new RegExp('.*drive.google.com.*'),
          message: 'Vui lòng nhập link Google drive',
        },
      };
    }
    return { pattern: null };
  };

  return (
    <>
      <Modal
        open={open}
        title={value?.id ? 'Sửa bài học' : 'Thêm bài học'}
        closeIcon={false}
        okText="Đồng ý"
        cancelText="Hủy"
        width={500}
        maskClosable={false}
        onOk={handleSubmit(handleSubmitForm)}
        onCancel={handleCancel}
        bodyClassName="overflow-hidden"
        okButtonProps={{
          loading: isLoading,
          disabled: isLoading,
        }}
      >
        <FormTera form={form} isLoading={false}>
          <FormTeraItem
            label="Tên bài học"
            name="name"
            rules={[
              {
                required: messageValidate.emptyText,
              },
            ]}
          >
            <Input maxLength={100} ref={ref} />
          </FormTeraItem>

          <FormTeraItem label="Nội dung" name="content">
            <CkeditorForm />
          </FormTeraItem>
          <FormTeraItem label="Video bài học" name="link_url_type">
            <Radio
              inline
              onChangeCapture={() => {
                form.setValue('link_url', '');
                form.clearErrors('link_url');
              }}
              className="gap-4"
              listOption={typeOptions}
            />
          </FormTeraItem>
          <FormTeraItem
            name="link_url"
            label=""
            key={typeFile}
            rules={[{ ...getRules() }]}
          >
            {renderTypeFile[typeFile]}
          </FormTeraItem>
        </FormTera>
      </Modal>
    </>
  );
};

export default LectureItemForm;
