import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { messageWarning } from '_common/constants/message';
import InputNumber from '_common/dof/Control/InputNumber';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { usePrompt } from '_common/hooks/usePrompt';
import { useForm } from 'react-hook-form';
import { BookmarkOutlined, Button, notification } from 'tera-dls';
import CommissionConfigApi from './api';
import { useEffect } from 'react';

const CommissionConfig = () => {
  const form = useForm({
    defaultValues: {
      course_offline: 1,
      course_online: 1,
    },
  });
  const isDirty = form.formState.isDirty;
  const queryClient = useQueryClient();

  const {
    data: dataDetails,
    isLoading,
    refetch,
  } = useQuery(
    ['get-course-commission-config-list'],
    () => CommissionConfigApi.getList(),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
  }, []);

  usePrompt(
    <p className="break-word">
      <p>{messageWarning.WARNING_EXIT_1}</p>
      <p>{messageWarning.WARNING_EXIT_2}</p>
    </p>,
    isDirty,
  );

  const { mutate: mutateUpdate, isLoading: isLoadingMutate } = useMutation(
    (params: any) => CommissionConfigApi.save(params),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-course-commission-config-list']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleSubmitForm = (values) => {
    mutateUpdate({
      params: {
        ...values,
      },
    });
  };

  useEffect(() => {
    if (dataDetails) {
      form.reset({
        course_offline: dataDetails?.course_offline,
        course_online: dataDetails?.course_online,
      });
    }
  }, [dataDetails]);

  const disabled = isLoading || isLoadingMutate;

  return (
    <div className="tera-page !bg-none">
      <HeaderViewList
        title="Cấu hình hoa hồng khóa học"
        buttonAddRender={() => (
          <Button
            type="success"
            icon={<BookmarkOutlined className="w-4" />}
            onClick={() => form.handleSubmit(handleSubmitForm)()}
            disabled={!isDirty || disabled}
            loading={disabled}
            className="bg-green-500 hover:bg-green-600"
          >
            Lưu
          </Button>
        )}
      >
        <FormTera
          form={form}
          className="grid grid-cols-2 gap-x-2.5 rounded-md bg-white p-2.5 shadow-[0_4px_4px_0_rgba(0,0,0,0.1),0_-4px_4px_0_rgba(0,0,0,0.03)]"
        >
          <FormTeraItem
            name="course_offline"
            label="Khóa học offline"
            help={'<100'}
          >
            <InputNumber
              min={1}
              max={100}
              suffix={<span className="mr-5">%</span>}
            />
          </FormTeraItem>
          <FormTeraItem
            name="course_online"
            label="Khóa học online"
            help={'<100'}
          >
            <InputNumber
              min={1}
              max={100}
              suffix={<span className="mr-5">%</span>}
            />
          </FormTeraItem>
        </FormTera>
      </HeaderViewList>
    </div>
  );
};

export default CommissionConfig;
