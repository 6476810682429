const RevenueIcon = (props) => (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.1514 10.8462V8.69231C14.1514 7.1629 12.9116 5.92308 11.3822 5.92308H10.1514C9.64164 5.92308 9.22837 5.5098 9.22837 5V3.76923C9.22837 2.23983 7.98854 1 6.45913 1H4.92067M6.76683 1H2.76683C2.25703 1 1.84375 1.41328 1.84375 1.92308V16.0769C1.84375 16.5867 2.25703 17 2.76683 17H13.2284C13.7382 17 14.1514 16.5867 14.1514 16.0769V8.38462C14.1514 4.30621 10.8452 1 6.76683 1Z"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.59054 9.13695V12.966M6.63328 12.0668L6.91372 12.2771C7.28754 12.5575 7.89362 12.5575 8.26744 12.2771C8.64127 11.9967 8.64127 11.5421 8.26744 11.2618C8.08052 11.1216 7.83551 11.0515 7.59051 11.0515C7.35919 11.0515 7.12793 10.9814 6.95144 10.8412C6.5985 10.5608 6.5985 10.1063 6.95144 9.82588C7.30438 9.5455 7.87662 9.5455 8.22956 9.82588L8.36195 9.93104M10.4623 11.0515C10.4623 12.6375 9.17659 13.9233 7.59054 13.9233C6.0045 13.9233 4.71875 12.6375 4.71875 11.0515C4.71875 9.46543 6.0045 8.17969 7.59054 8.17969C9.17659 8.17969 10.4623 9.46543 10.4623 11.0515Z"
      stroke="white"
      stroke-width="0.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export default RevenueIcon;
