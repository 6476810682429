import { IRouteProps } from '_common/interface/router';
import { COMMISSION_CONFIG_URL } from './url';
import CommissionConfig from '.';

export const CommissionConfigRouter: IRouteProps[] = [
  {
    key: COMMISSION_CONFIG_URL.list.key,
    path: COMMISSION_CONFIG_URL.list.shortenUrl,
    component: <CommissionConfig />,
  },
];
