import Menu13 from '_common/component/Icons/Menu13';
import Menu17 from '_common/component/Icons/Menu17';
import Menu18 from '_common/component/Icons/Menu18';
import Menu3 from '_common/component/Icons/Menu3';
import Menu4 from '_common/component/Icons/Menu4';
import AffiliateIcon from 'styles/images/Icons/AffiliateIcon';
import LecturerIcon from 'styles/images/report/LecturerIcon';
import OrderIcon from 'styles/images/report/OrderIcon';
import RevenueIcon from 'styles/images/report/RevenueIcon';
import StudentIcon from 'styles/images/report/StudentIcon';
import {
  ChartPieOutlined,
  DocumentChartBarOutlined,
  HomeOutlined,
  LockClosedOutlined,
  PlayCircleOutlined,
  ReceiptPercentOutlined,
  StopCircleOutlined,
  TruckOutlined,
  UserGroupOutlined,
  UserOutlined,
  UserPlusOutlined,
  UsersOutlined,
  VideoCameraOutlined,
  WalletOutlined,
  WrenchScrewdriverOutlined,
} from 'tera-dls';

const menu = {
  groupMenu: [
    {
      id: 'r1',
      code: 'dashboard',
      title: 'Trang chủ',
      path: '/dashboard',
      iconNode: <HomeOutlined />,
      key: 'dashboard',
    },
    {
      id: 'r2',
      code: 'course-management',
      title: 'Khóa học',
      path: 'course-management/course/list',
      iconNode: <PlayCircleOutlined />,
      key: 'course-management',
    },
    {
      id: 'r3',
      code: 'user-management',
      title: 'Người dùng',
      path: '/user-management/user/list',
      iconNode: <UsersOutlined />,
      key: 'user-management',
    },
    {
      id: 'r4',
      code: 'lecturer-management',
      title: 'Giảng viên',
      path: '/lecturer-management/lecturer/list',
      iconNode: <UserOutlined />,
      key: 'lecturer-management',
    },
    {
      id: 'r5',
      code: 'student-management',
      title: 'Học viên',
      path: '/student-management/student/list',
      iconNode: <UserGroupOutlined />,
      key: 'student-management',
    },
    {
      id: 'r6',
      code: 'report',
      title: 'Báo cáo',
      path: 'report/revenue/list',
      iconNode: <ChartPieOutlined className="text-gray-700" />,
      key: 'report',
    },
    {
      id: 'r7',
      code: 'affiliate',
      title: 'Affiliates',
      path: '/affiliate/overview/list',
      iconNode: <AffiliateIcon />,
      key: 'affiliate',
    },
    {
      id: 'r8',
      code: 'sale-management',
      title: 'Bán hàng',
      path: 'sale-management/product/list',
      iconNode: <TruckOutlined className="text-gray-500" />,
      key: 'sale-management',
    },
    {
      id: 'r9',
      code: 'finance',
      title: 'Tài chính',
      path: '/finance/refund/list',
      iconNode: <Menu13 />,
      key: 'finance',
    },
    {
      id: 'r10',
      code: 'system',
      title: 'Hệ thống',
      path: 'system/guide-video',
      iconNode: <Menu17 />,
      key: 'system',
    },
  ],
  parentGroup: [
    {
      id: 1,
      title: '',
      key: 'empty',
      parentKey: 'system',
    },
    {
      id: 2,
      title: 'Sản phẩm',
      key: 'product-group',
      parentKey: 'sale-management',
    },
    {
      id: 3,
      title: 'Đơn hàng',
      key: 'sale-order-group',
      parentKey: 'sale-management',
    },
    {
      id: 4,
      title: 'Khoá học',
      key: 'course-group',
      parentKey: 'course-management',
    },
    {
      id: 5,
      title: 'Đơn hàng',
      key: 'order-group',
      parentKey: 'sale',
    },
    {
      id: 6,
      title: 'Hóa đơn',
      key: 'invoice-group',
      parentKey: 'finance',
    },
    {
      id: 13,
      title: 'Thanh toán',
      key: 'finance-payment',
      parentKey: 'finance',
    },
  ],
  parentMenu: [
    {
      id: 1,
      title: 'Video hướng dẫn',
      path: 'system/guide-video',
      iconNode: <VideoCameraOutlined />,
      key: 'guide-video',
      parentKey: 'system',
    },
    {
      id: 2,
      title: 'Ưu đãi và khuyến mãi',
      path: 'system/promotion',
      iconNode: <ReceiptPercentOutlined />,
      key: 'promotion',
      parentKey: 'system',
    },
    {
      id: 3,
      title: 'Quản lý người dùng',
      path: 'user-management/user/list',
      iconNode: <UserOutlined />,
      key: 'user',
      parentKey: 'user-management',
    },

    {
      id: 5,
      title: 'Quản lý giảng viên',
      path: 'lecturer-management/lecturer/list',
      iconNode: <UserOutlined />,
      key: 'lecturer',
      parentKey: 'lecturer-management',
    },
    {
      id: 6,
      title: 'Quản lý học viên',
      path: 'student-management/student/list',
      iconNode: <UserGroupOutlined />,
      key: 'student',
      parentKey: 'student-management',
    },
    {
      id: 7,
      title: 'Sản phẩm',
      path: 'sale-management/product/list',
      iconNode: <LockClosedOutlined />,
      key: 'product',
      parentGroupKey: 'product-group',
      parentKey: 'sale-management',
    },
    {
      id: 8,
      title: 'Danh mục',
      path: 'sale-management/category/list',
      iconNode: <Menu18 />,
      key: 'category',
      parentGroupKey: 'product-group',
      parentKey: 'sale-management',
    },
    {
      id: 9,
      title: 'Khóa học',
      path: 'course-management/course/list',
      iconNode: <PlayCircleOutlined />,
      key: 'course',
      parentGroupKey: 'course-group',
      parentKey: 'course-management',
    },
    {
      id: 10,
      title: 'Hoạt động khóa học',
      path: 'course-management/course-activity/list',
      iconNode: <StopCircleOutlined />,
      key: 'course-activity',
      parentGroupKey: 'course-group',
      parentKey: 'course-management',
    },
    {
      id: 11,
      title: 'Danh mục khóa học',
      path: 'course-management/course-category/list',
      iconNode: <Menu18 height={16} />,
      key: 'course-category',
      parentKey: 'course-management',
      parentGroupKey: 'course-group',
    },
    {
      id: 12,
      title: 'Đơn khóa học',
      path: 'course-management/course-order/list',
      iconNode: <DocumentChartBarOutlined />,
      key: 'course-order',
      parentKey: 'course-management',
      parentGroupKey: 'course-group',
    },
    {
      id: 13,
      title: 'Chứng chỉ',
      path: 'course-management/certificate/list',
      iconNode: <Menu3 />,
      key: 'certificate',
      parentKey: 'course-management',
      parentGroupKey: 'course-group',
    },
    // {
    //   id: 13,
    //   title: 'Đề xuất khuyến mãi',
    //   path: 'course-management/promotion-suggestion/list',
    //   iconNode: <Menu19 height={16} />,
    //   key: 'promotion-suggestion',
    //   parentKey: 'course-management',
    //   parentGroupKey: 'course-group',
    // },
    {
      id: 14,
      title: 'Đăng ký giảng viên',
      path: 'lecturer-management/lecturer-application/list',
      iconNode: <Menu18 />,
      key: 'lecturer-application',
      parentKey: 'lecturer-management',
    },
    {
      id: 15,
      title: 'Đơn bán hàng',
      path: 'sale-management/sale-order/list',
      iconNode: <TruckOutlined />,
      key: 'sale-order',
      parentGroupKey: 'order-group',
      parentKey: 'sale-management',
    },
    // {
    //   id: 16,
    //   title: 'Đơn trả hàng bán',
    //   path: 'sale-management/sale-order-return/list',
    //   iconNode: <ArrowUturnLeftSolid />,
    //   key: 'sale-order-return',
    //   parentGroupKey: 'order-group',
    //   parentKey: 'sale-management',
    // },
    {
      id: 26,
      title: 'Danh sách hoàn tiền',
      path: '/finance/refund/list',
      iconNode: <Menu4 className="w-4 h-4" />,
      key: 'refund',
      parentKey: 'finance',
      parentGroupKey: 'invoice-group',
    },
    {
      id: 25,
      title: 'Hóa đơn',
      path: '/finance/invoice/list',
      icon: 'document-text',
      key: 'invoice',
      parentKey: 'finance',
      parentGroupKey: 'invoice-group',
    },
    {
      id: 17,
      title: 'Phiếu thu',
      path: '/finance/cash-receipt/list',
      icon: 'custom-document-import',
      key: 'cash-receipt',
      parentKey: 'finance',
      parentGroupKey: 'invoice-group',
    },
    {
      id: 18,
      title: 'Phiếu chi',
      path: '/finance/expense-voucher/list',
      icon: 'custom-document-export',
      key: 'expense-voucher',
      parentKey: 'finance',
      parentGroupKey: 'invoice-group',
    },
    {
      id: 19,
      title: 'Thống kê thu chi',
      path: '/finance/statistic',
      icon: 'chart-pie',
      key: 'statistic',
      parentKey: 'finance',
      parentGroupKey: 'invoice-group',
    },
    {
      id: 20,
      title: 'Phương thức thanh toán',
      path: '/finance/payment-method/list',
      icon: 'credit',
      key: 'payment-method',
      parentKey: 'finance',
      parentGroupKey: 'finance-payment',
      permission: 'fin_payment_view_payment_method_list',
    },
    {
      id: 21,
      title: 'Lịch sử giao dịch',
      path: '/finance/transaction-history/list',
      icon: 'clock',
      key: 'transaction-history',
      parentKey: 'finance',
      parentGroupKey: 'finance-payment',
    },
    {
      id: 22,
      title: 'Tiếp thị liên kết',
      path: '/affiliate/overview/list',
      iconNode: <UserPlusOutlined />,
      key: 'overview',
      parentKey: 'affiliate',
    },
    {
      id: 23,
      title: 'Yêu cầu rút tiền',
      path: '/affiliate/withdrawal-request/list',
      iconNode: <WalletOutlined />,
      key: 'withdrawal-request',
      parentKey: 'affiliate',
    },
    {
      id: 24,
      title: 'Cấu hình affiliates',
      path: '/affiliate/config/list',
      iconNode: <WrenchScrewdriverOutlined />,
      key: 'config',
      parentKey: 'affiliate',
    },
    {
      id: 27,
      title: 'Cấu hình hoa hồng khóa học',
      path: 'system/commission-config',
      iconNode: <WrenchScrewdriverOutlined />,
      key: 'commission-config',
      parentKey: 'system',
    },
    {
      id: 28,
      title: 'Báo cáo doanh thu',
      path: 'report/revenue/list',
      iconNode: <RevenueIcon />,
      key: 'revenue',
      parentKey: 'report',
    },
    {
      id: 29,
      title: 'Báo cáo học viên',
      path: 'report/student/list',
      iconNode: <StudentIcon />,
      key: 'student',
      parentKey: 'report',
    },
    {
      id: 30,
      title: 'Báo cáo khóa học',
      path: 'report/student/list',
      iconNode: <StudentIcon />,
      key: 'student',
      parentKey: 'report',
    },
    {
      id: 31,
      title: 'Báo cáo giảng viên',
      path: 'report/lecturer/list',
      iconNode: <LecturerIcon />,
      key: 'lecturer',
      parentKey: 'report',
    },
    {
      id: 32,
      title: 'Báo cáo đơn hàng',
      path: 'report/order/list',
      iconNode: <OrderIcon />,
      key: 'order',
      parentKey: 'report',
    },
  ],
  subMenu: [
    {
      id: 3,
      name: 'Báo cáo chung',
      path: '/admin/config-affiliates/general',
      status: 'active',
      code: 's3',
      parentKey: 'config-affiliates',
      activeKeys: 'general',
    },
  ],
};
export default menu;
