import { IRouteProps } from '_common/interface/router';
import Revenue from '.';
import { REVENUE_URL } from './url';

export const RevenueRouter: IRouteProps[] = [
  {
    key: REVENUE_URL.list.key,
    path: REVENUE_URL.list.shortenUrl,
    component: <Revenue />,
  },
];
