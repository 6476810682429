export const ORDER_STATUS = {
  pending: 'Chờ duyệt',
  approve: 'Đã duyệt',
  reject: 'Đã hủy',
  complete: 'Hoàn thành',
};

export const ORDER_STATUS_COLOR = {
  pending: 'yellow03',
  approve: 'green03',
  reject: 'gray01',
  complete: 'blue03',
};

export const discountType = {
  not_available: 'Không có chiết khấu',
  fixed: 'Cố định',
  by_percentage: 'Theo % hóa đơn',
};

export const REJECT_REASON = {
  1: 'Khách hàng thay đổi ý định',
  2: 'Sai sản phẩm',
  3: 'Thời gian giao hàng quá lâu',
  4: 'Thay đổi địa chỉ giao hàng',
  5: 'Lý do khác',
};
