import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import UploadFiles from '_common/dof/UploadFiles';
import { useStores } from '_common/hooks';
import { observer } from 'mobx-react-lite';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { notification } from 'tera-dls';
import CourseAttachmentApi from '../../api/Attachment';

interface IProps {
  mode?: 'create' | 'update';
  dataDetail?: any;
}

const Attachment = ({ mode = 'create', dataDetail }: IProps, ref) => {
  const {
    course: { attachments, setAttachments, nextStep },
  } = useStores();
  const [files, setFiles] = useState<any>([]);
  const queryClient = useQueryClient();
  const isUpdateMode = mode == 'update';

  const { data: list, refetch: fetchData } = useQuery(
    ['get-course-attachment-list', dataDetail?.id],
    () =>
      CourseAttachmentApi.getList({
        course_id: dataDetail?.id,
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!dataDetail?.id,
    },
  );

  useEffect(() => {
    dataDetail?.id && fetchData();
  }, [dataDetail?.id]);

  const { mutate: mutateAdd } = useMutation(
    (variables: any) => CourseAttachmentApi.create(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-course-attachment-list']);
          queryClient.invalidateQueries(['get-course-summary-detail']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  useImperativeHandle(ref, () => {
    return {
      nextStep: () => {
        nextStep();
      },
    };
  });

  useEffect(() => {
    if (isUpdateMode && !!list?.data) {
      setFiles(list?.data);
      return;
    }
    attachments?.length > 0 && setFiles(attachments);
  }, [attachments, list?.data, isUpdateMode]);

  const { mutate: mutateDelete } = useMutation(
    (variables: any) => CourseAttachmentApi.delete(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-course-attachment-list']);
          queryClient.invalidateQueries(['get-course-summary-detail']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );
  mutateDelete;
  // const handleDelete = (record): void => {
  //   confirm.warning({
  //     title: 'Xoá File',
  //     content: (
  //       <>
  //         <p>Bạn có chắc muốn xóa thời gian</p>
  //         <p>này không?</p>
  //       </>
  //     ),
  //     onOk: () => {
  //       if (isUpdateMode) {
  //         actionRef.current?.deleteRow(record?.id, record?.index);
  //         return;
  //       }
  //       setData((prev) => {
  //         const data = prev?.filter((i) => i?.id !== record?.id);
  //         setSchedule(data);
  //         return data;
  //       });
  //     },
  //   });
  // };

  return (
    <>
      <div className="space-y-2.5">
        <UploadFiles
          fileList={files}
          maxFileNumber={10}
          maxSize={20}
          // mode="edit"
          isCount={false}
          onReceiveFiles={(file, files) => {
            setFiles(files);
            if (isUpdateMode) {
              mutateAdd({ params: { ...file, course_id: dataDetail?.id } });
              return;
            }
            setAttachments(files);
          }}
          onRemove={(fileDelete) => {
            const newList = files?.filter((file) => file.id !== fileDelete?.id);
            setFiles(newList);
            if (isUpdateMode) {
              mutateDelete({ id: fileDelete?.id });
              return;
            }
            setAttachments(newList);
          }}
        />
      </div>
    </>
  );
};

export default observer(forwardRef(Attachment));
