import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import VideoBackground from '_common/component/VideoBackground';
import TableTera from '_common/dof/TableTera';
import _ from 'lodash';
import { IFormModel } from 'pages/CourseManagement/Course';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import no_homework from 'styles/images/no_homework.png';
import {
  Button,
  DropdownItem,
  FunnelOutlined,
  getQueryParams,
  Image,
  updateQueryParams,
} from 'tera-dls';
import StudentProductDetail from './Detail';
import StudentProductFilter from './Filter';

const StudentProduct = ({ dataDetail }) => {
  dataDetail;
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const [openFormModel, setOpenFormModel] = useState<IFormModel>({
    open: false,
  });
  const [openFilter, setOpenFilter] = useState<any>();

  const params = _.pick(queryParams, ['studentProduct']);
  const paramsObject = params?.studentProduct
    ? JSON.parse(params?.studentProduct)
    : {};

  const handleUpdateQueryParams = (data: Record<string, any>): void => {
    const paramString = updateQueryParams({
      ...queryParams,
      studentProduct: JSON.stringify({
        ...paramsObject,
        ...data,
      }),
    });
    navigate(location.pathname + paramString);
  };

  const handleFilter = (values: Record<string, any>): void => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const generateDropDownItems = (record): DropdownItem[] => {
    const dropdownItems: DropdownItem[] = [
      {
        key: 1,
        label: <a>Xem</a>,
        onClick: () => setOpenFormModel({ open: true, value: record?.id }),
      },
    ];

    return dropdownItems;
  };

  const columns: any = [
    {
      title: 'Học viên',
      dataIndex: 'full_name',
      width: 200,
      render: (value) => (
        <div className="flex gap-2.5 items-center">
          <Image
            src={dataDetail?.image_url}
            containerClassName="!size-[24px] rounded-full overflow-hidden"
            imageClassName="object-cover"
          />
          <span className="line-clamp-1 text-blue-600">{value}</span>
        </div>
      ),
    },
    {
      title: 'Phần học',
      dataIndex: 'phone',
      width: 150,
    },
    {
      title: 'Bài học',
      dataIndex: 'email',
      width: 150,
    },
    {
      title: 'Nội dung thành phần',
      width: 150,
      dataIndex: 'number_course',
      render: () => (
        <div className="flex gap-2.5 items-center">
          <VideoBackground
            value={{
              url: 'https://bake-api.teravn.com/assets/upload/lecture-item/1726456051_download-2.mp4',
            }}
            isView
            isReview={false}
            size="small"
            type={'uploaded_video'}
          />
          <VideoBackground
            value={{
              url: 'https://youtu.be/Bhg-Gw953b0?si=TGZqwhlF8O74Sj3q',
            }}
            isView
            isReview={false}
            size="small"
            type={'youtube_link'}
          />
          <VideoBackground
            value={{
              url: 'https://drive.google.com/file/d/1ZgHHHyQeSGqjDKtbDQ4m8dzewByRr3b8/view?usp=drive_link',
            }}
            size="small"
            isView
            isReview={false}
            type={'gg_drive_link'}
          />
          <span>+3</span>
        </div>
      ),
    },
    {
      title: 'Hiện lên app',
      width: 150,
      dataIndex: 'student',
      editable: true,
      inputProps: {
        onChange: (e) => console.log(e.target.checked),
      },
      type: 'switch',
    },

    {
      title: '',
      dataIndex: 'operation',
      width: 46,
      fixed: 'right',
      render: (_, record) => (
        <ActionDropdown
          dropdownItems={generateDropDownItems(record)}
          trigger="click"
          containerClassName="w-[50px]"
          placement="bottom-end"
        />
      ),
    },
  ];

  return false ? (
    <div className="w-full flex justify-center py-5">
      <div className="flex flex-col justify-center gap-[24px]">
        <img src={no_homework} className="size-[100px] ml-1" />
        <p className="text-[#374151] tracking-[1px]">Chưa có bài tập nào</p>
      </div>
    </div>
  ) : (
    <>
      <div className="space-y-2.5">
        <div className="flex gap-2.5 justify-end">
          <Button
            type="alternative"
            className="rounded-xsm py-1 px-1 bg-blue-50"
            icon={
              <FunnelOutlined className=" text-blue-500 rounded-[4px] shrink-0" />
            }
            onClick={() => setOpenFilter(true)}
          />
        </div>
        <TableTera
          // loading={isFetching}
          wrapperClassName="shadow-none"
          rowKey={'id'}
          mode="editable-cell"
          data={[{ id: 1, full_name: 'Trần Nhựt Thông', is_active: 1 }]}
          className="center-table"
          // pagination={{
          //   onChange: handleChangePage,
          //   total: response?.total || 0,
          //   current: response?.current_page,
          //   pageSize: response?.per_page || 10,
          //   to: response?.to,
          //   from: response?.from || 10,
          // }}
          columns={columns}
        />
      </div>
      {openFilter && (
        <StudentProductFilter
          courseId={dataDetail?.id}
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={paramsObject}
        />
      )}
      {openFormModel.open && (
        <StudentProductDetail
          open={openFormModel.open}
          onClose={() => setOpenFormModel({ open: false })}
          value={openFormModel.value}
        />
      )}
    </>
  );
};

export default StudentProduct;
