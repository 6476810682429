import { useQuery } from '@tanstack/react-query';
import CourseOrderApi from 'pages/CourseManagement/CourseOrder/api';
import CourseOrderTable from 'pages/CourseManagement/CourseOrder/containers/CourseOrderTable';
import React, { useEffect, useState } from 'react';
import { PaginationProps } from 'tera-dls';

const Online = ({ id, classification = 'online' }) => {
  const [params, setParams] = useState<any>({});

  const {
    data: response,
    refetch,
    isFetching,
  } = useQuery(
    ['get-course-order-list', params, id, classification],
    () =>
      CourseOrderApi.getList({
        page: 1,
        limit: 10,
        ...params,
        student_id: id,
        classification,
      }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    id && refetch();
  }, [id]);

  const handleChangePage: PaginationProps['onChange'] = (
    page: number,
    pageSize: number,
  ): void => {
    setParams((prev = {}) => ({ ...prev, page, limit: pageSize }));
  };

  return (
    <CourseOrderTable
      loading={isFetching}
      data={response?.data ?? []}
      isShowSuccessStatus={true}
      pagination={{
        onChange: handleChangePage,
        total: response?.total || 0,
        current: response?.current_page,
        pageSize: response?.per_page || 10,
        to: response?.to,
        from: response?.from || 10,
      }}
    />
  );
};

export default Online;
