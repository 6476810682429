import { useMutation, useQueryClient } from '@tanstack/react-query';
import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import TableTera from '_common/dof/TableTera';
import useConfirm from '_common/hooks/useConfirm';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  DropdownItem,
  formatCurrency,
  notification,
  PlusOutlined,
  Tag,
} from 'tera-dls';
import CourseApi from './api';
import {
  COURSE_METHOD,
  COURSE_METHOD_COLOR,
  COURSE_STATUS,
  COURSE_STATUS_COLOR,
  COURSE_SYSTEM_STATUS,
  COURSE_SYSTEM_STATUS_COLOR,
  COURSE_TYPE,
} from './constants';
import UserFilter from './containers/Filter';
import Searching from './containers/Searching';
import { COURSE_URL } from './url';
import HoverQuickView from '_common/component/HoverQuickView';

interface IProps {
  onSearch: (keyword: any) => void;
  headerProps?: any;
  loading?: boolean;
  value: any;
  onChangePage: (values: any) => void;
  onFilter: (values: any) => void;
  filterValue?: any;
  isDetail?: boolean;
}
const Overview = ({
  onSearch,
  headerProps,
  loading,
  value,
  onChangePage,
  onFilter,
  filterValue,
  isDetail = false,
}: IProps) => {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const { mutate: mutateApprove } = useMutation(
    (variables: any) => CourseApi.approve(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-course-list']);
          queryClient.invalidateQueries(['get-course-summary-list']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );
  const { mutate: mutateReject } = useMutation(
    (variables: any) => CourseApi.reject(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-course-list']);
          queryClient.invalidateQueries(['get-course-summary-list']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleApprove = (id: number, name: string, isOnline = false): void => {
    confirm.warning({
      title: `Xác nhận duyệt khóa học ${isOnline ? 'online' : 'offline'}`,
      content: (
        <p className="break-word">
          <p>
            Bạn có chắc muốn xác nhận duyệt khóa học{' '}
            {isOnline ? 'online' : 'offline'}
          </p>
          <p>
            <span className="font-bold break-word">{` ${name ?? ''} `} </span>
            này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateApprove({ id });
      },
    });
  };

  const handleReject = (id: number, name: string, isOnline = false): void => {
    confirm.warning({
      title: `Xác nhận từ chối khóa học ${isOnline ? 'online' : 'offline'}`,
      content: (
        <p className="break-word">
          <p>
            Bạn có chắc muốn xác nhận từ chối học{' '}
            {isOnline ? 'online' : 'offline'}
          </p>
          <p>
            <span className="font-bold break-word">{` ${name ?? ''} `} </span>
            này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateReject({ id });
      },
    });
  };

  const { mutate: mutateDelete } = useMutation(
    (variables: any) => CourseApi.delete(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-course-list']);
          queryClient.invalidateQueries(['get-course-summary']);
          queryClient.invalidateQueries(['get-lecturer-summary-detail']);
          notification.success({
            message: res?.msg,
          });
          navigate(COURSE_URL.list.path);
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleDelete = (id: number, name: string): void => {
    confirm.warning({
      title: 'Xóa khóa học',
      content: (
        <p className="break-word">
          <p>Bạn có chắc muốn xóa khóa học</p>
          <p>
            <span className="font-bold break-word">{` ${name ?? ''} `} </span>
            này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateDelete({ id });
      },
    });
  };

  const generateDropDownItems = (record): DropdownItem[] => {
    const dropdownItems: DropdownItem[] = [
      {
        key: 1,
        label: <a>Xem</a>,
        onClick: () => navigate(`${COURSE_URL.detail.path}/${record?.id}`),
      },
      ...(record?.status === 'pending'
        ? [
            {
              key: 4,
              label: <a>Duyệt</a>,
              onClick: () =>
                handleApprove(
                  record?.id,
                  record?.name,
                  record?.classification == 'online',
                ),
            },
            {
              key: 5,
              label: <a>Từ chối</a>,
              onClick: () =>
                handleReject(
                  record?.id,
                  record?.name,
                  record?.classification == 'online',
                ),
            },
          ]
        : []),

      {
        key: 6,
        label: <a>Sửa</a>,
        onClick: () => navigate(`${COURSE_URL.update.path}/${record?.id}`),
      },
      ...(record?.is_active === 0
        ? [
            {
              key: '3',
              label: <span className="text-red-500">Xóa</span>,
              onClick: () => handleDelete(record?.id, record?.name),
            },
          ]
        : []),
    ];

    return dropdownItems;
  };

  const columns: any = [
    {
      title: 'Mã khóa học',
      dataIndex: 'code',
      width: 100,
    },
    {
      title: 'Tên khóa học',
      dataIndex: 'name',
      width: 150,
      render: (value, record) => (
        <span
          className="line-clamp-1 text-blue-600 cursor-pointer inline-block"
          onClick={() => {
            navigate(`${COURSE_URL.detail.path}/${record?.id}`);
          }}
        >
          {value}
        </span>
      ),
    },
    {
      title: 'Giảng viên',
      dataIndex: 'lecturer',
      width: 150,
      render: (value) => (
        <HoverQuickView
          name={value?.full_name}
          avatarUrl={value?.avatar}
          email={value?.email}
          phone={value?.phone}
        >
          <span className="line-clamp-1 text-blue-600">{value?.full_name}</span>
        </HoverQuickView>
      ),
    },
    {
      title: 'Danh mục khóa học',
      dataIndex: 'category',
      width: 100,
      render: (val) => val?.name,
    },
    {
      title: 'Loại khóa học',
      width: 100,
      dataIndex: 'type',
      render: (val) => COURSE_TYPE[val],
    },
    {
      title: 'Phân loại khóa học',
      width: 100,
      dataIndex: 'classification',
      render: (val) => (
        <span className={`${COURSE_METHOD_COLOR[val]}`}>
          {COURSE_METHOD[val]}
        </span>
      ),
    },
    {
      title: 'Giá khóa học',
      width: 100,
      dataIndex: 'price',
      render: (val) => formatCurrency(val ?? 0),
    },
    {
      title: 'Giá khuyến mãi',
      width: 100,
      dataIndex: 'discount_price',
      render: (val) => formatCurrency(val ?? 0),
    },
    {
      title: 'Học viên đã đăng ký',
      width: 100,
      dataIndex: 'students_count',
      render: (val) => val ?? 0,
    },
    {
      title: 'Trạng thái khóa học',
      width: 120,
      dataIndex: 'is_active',
      render: (val) => (
        <Tag color={COURSE_STATUS_COLOR[val]}>{COURSE_STATUS[val]}</Tag>
      ),
    },
    {
      title: 'Trạng thái hệ thống',
      width: 100,
      dataIndex: 'status',
      render: (val) => (
        <Tag color={COURSE_SYSTEM_STATUS_COLOR[val]}>
          {COURSE_SYSTEM_STATUS[val]}
        </Tag>
      ),
    },
    {
      title: '',
      dataIndex: 'operation',
      width: 46,
      fixed: 'right',
      render: (_, record) => (
        <ActionDropdown
          dropdownItems={generateDropDownItems(record)}
          trigger="click"
          containerClassName="w-[50px]"
          placement="bottom-end"
        />
      ),
    },
  ];

  return (
    <>
      <HeaderViewListV2
        {...(!isDetail
          ? {
              title: 'DANH SÁCH KHÓA HỌC',
              buttonAddRender: () => (
                <div className="flex gap-2.5">
                  <Button
                    onClick={() => navigate(`${COURSE_URL.create.path}/online`)}
                    className="px-[12px] py-[5px] rounded-[4px] flex gap-2.5 bg-blue-500"
                  >
                    <div className="flex gap-[5px] items-center">
                      <PlusOutlined className="w-[17px] h-[17px]" />
                      <span>Thêm khóa học online</span>
                    </div>
                  </Button>
                  <Button
                    onClick={() =>
                      navigate(`${COURSE_URL.create.path}/offline`)
                    }
                    className="px-[12px] py-[5px] rounded-[4px] flex gap-2.5 bg-blue-500"
                  >
                    <div className="flex gap-[5px] items-center">
                      <PlusOutlined className="w-[17px] h-[17px]" />
                      <span>Thêm khóa học offline</span>
                    </div>
                  </Button>
                </div>
              ),
              actionLeftRender: <Searching onSearch={onSearch} />,
              onClickFilter: () => setOpenFilter(true),
            }
          : {
              titleClassName: 'mb-0',
            })}
        {...headerProps}
      >
        <div className="page-content-v2 h-full !mb-2.5 !gap-y-0">
          <TableTera
            loading={loading}
            // wrapperClassName="shadow-none"
            className="center-table"
            rowKey={'id'}
            data={value?.data ?? []}
            pagination={{
              onChange: onChangePage,
              total: value?.total || 0,
              current: value?.current_page,
              pageSize: value?.per_page || 10,
              to: value?.to,
              from: value?.from || 10,
            }}
            columns={columns}
          />
        </div>
      </HeaderViewListV2>
      {openFilter && (
        <UserFilter
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          onFilter={onFilter}
          initialValue={filterValue}
        />
      )}
    </>
  );
};

export default Overview;
