import { Row } from 'tera-dls';
import GroupByType from './containers/GroupByType';
import StatisticCashExpenseByStudent from './containers/StatisticCashExpenseByStudent';
import StatisticCashExpenseByLecturer from './containers/StatisticCashExpenseByLecturer';

const Statistic = () => {
  return (
    <div className="p-2.5">
      <div className="font-semibold	text-base uppercase mb-2.5">
        Thống kê thu chi
      </div>
      <Row>
        <GroupByType />
        <StatisticCashExpenseByStudent />
        <StatisticCashExpenseByLecturer />
      </Row>
    </div>
  );
};

export default Statistic;
