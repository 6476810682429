import { useMutation, useQueryClient } from '@tanstack/react-query';
import HoverQuickView from '_common/component/HoverQuickView';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import useConfirm from '_common/hooks/useConfirm';
import { useNavigate } from 'react-router-dom';
import {
  ColumnsType,
  DropdownItem,
  formatCurrency,
  formatDate,
  notification,
  Tag,
} from 'tera-dls';
import InvoiceApi from '../../api';
import {
  INVOICE_METHOD,
  STATUS_INVOICE,
  STATUS_INVOICE_APPROVED,
  STATUS_INVOICE_COLOR,
  TYPE_INVOICE,
} from '../../constants';
import { INVOICE_URL } from '../../url';

function InvoiceTable(props: ITeraTableProps) {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const { mutate: mutateApproval } = useMutation(
    (params: any) =>
      InvoiceApi.approval({ id: params?.id, params: params?.params }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-invoice-list']);
          queryClient.invalidateQueries(['get-cash-receipt-invoice-list']);
          queryClient.invalidateQueries(['get-expense-voucher-invoice-list']);
          queryClient.invalidateQueries(['get-transaction-history-list']);
          queryClient.invalidateQueries([
            'get-summary-transaction-history-list',
          ]);
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  // const { mutate: mutateDelete } = useMutation(
  //   (id: any) => InvoiceApi.delete({ id }),
  //   {
  //     onSuccess: (res) => {
  //       if (res?.code === 200) {
  //         notification.success({
  //           message: res?.msg,
  //         });
  //         navigate(-1);
  //         queryClient.invalidateQueries(['get-invoice-list']);
  //       }
  //     },
  //     onError: (error: any) => {
  //       ErrorToast({ errorProp: error?.data });
  //     },
  //   },
  // );

  // const handleDelete = (record) => {
  //   confirm.warning({
  //     title: 'Xác nhận xóa hóa đơn',
  //     content: (
  //       <div className="break-word">
  //         <p>Bạn có chắc chắn muốn xóa hóa đơn</p>
  //         <p>
  //           <b>{record?.invoice_code}</b> này không?
  //         </p>
  //       </div>
  //     ),
  //     onOk: () => {
  //       mutateDelete(record?.id);
  //     },
  //   });
  // };

  const handlePayment = (record) => {
    confirm.warning({
      title: 'Xác nhận thanh toán',
      content: (
        <div className="break-word">
          <p>Bạn có chắc chắn muốn thanh toán hóa đơn</p>
          <p>
            <b>{record?.invoice_code}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateApproval({
          id: record?.id,
          params: {
            status: STATUS_INVOICE_APPROVED,
          },
        });
      },
    });
  };

  const renderItemActions = (record) => {
    const items: DropdownItem[] = [
      {
        key: '1',
        label: 'Xem',
        onClick: () =>
          record?.id && navigate(`${INVOICE_URL.detail.path}/${record?.id}`),
      },
    ];

    if (
      record?.status !== STATUS_INVOICE_APPROVED &&
      record?.transaction_type === 'pay'
    )
      items.push({
        key: '2',
        label: 'Xác nhận thanh toán',
        onClick: () => handlePayment(record),
      });

    return items;
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Mã hóa đơn',
      dataIndex: 'invoice_code',
      width: 200,
      render: (name, record) => (
        <p
          className="line-clamp-2 break-word text-blue-600 cursor-pointer"
          onClick={() => navigate(`${INVOICE_URL.detail.path}/${record?.id}`)}
        >
          {name}
        </p>
      ),
    },
    {
      title: 'Loại giao dịch',
      dataIndex: 'transaction_type',
      width: 200,
      render: (type) => TYPE_INVOICE[type],
    },
    {
      title: 'Người dùng',
      dataIndex: 'user_name',
      width: 200,
      render: (user_name, record) => (
        <HoverQuickView
          avatarUrl={record?.user?.file_upload}
          name={record?.user?.full_name}
          code={record?.user?.code}
          email={record?.user?.email}
          phone={record?.user?.phone}
        >
          <p className="line-clamp-2">{user_name}</p>
        </HoverQuickView>
      ),
    },
    {
      title: 'Tổng giá trị',
      dataIndex: 'amount',
      width: 200,
      render: (total) => formatCurrency(total),
    },
    {
      title: 'Phương thức thanh toán',
      dataIndex: 'methods',
      width: 200,
      render: (method) => INVOICE_METHOD[method],
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 200,
      render: (status) => (
        <Tag color={STATUS_INVOICE_COLOR[status]}>{STATUS_INVOICE[status]}</Tag>
      ),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      width: 200,
      render: (created_at) =>
        created_at && formatDate(created_at, DATE_TIME_FORMAT),
    },
    {
      width: 60,
      fixed: 'right',
      render: (record) => {
        const items = renderItemActions(record);
        return <ActionDropdown dropdownItems={items} trigger="click" />;
      },
    },
  ];

  return (
    <TableTera
      zebra={false}
      columns={columns}
      loading={props?.loading}
      {...props}
    />
  );
}

export default InvoiceTable;
