import { useMutation, useQuery } from '@tanstack/react-query';
import CardFormV2 from '_common/component/CardForm/CardFormV2';
import HoverQuickView from '_common/component/HoverQuickView';
import { IconButton } from '_common/component/TableColumnCustom';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import useConfirm from '_common/hooks/useConfirm';
import { COURSE_TYPE } from 'pages/CourseManagement/Course/constants';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftOutlined,
  Breadcrumb,
  Button,
  Col,
  formatCurrency,
  formatDate,
  ItemType,
  notification,
  Row,
  StarOutlined,
  Tabs,
  Tag,
} from 'tera-dls';
import RefundApi from '../../api';
import {
  STATUS_REFUND,
  STATUS_REFUND_COLOR,
  TYPE_REFUND,
} from '../../constants';
import { REFUND_URL } from '../../url';
import DetectMedia from '_common/component/DetectMedia';

function RefundDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const confirm = useConfirm();

  const { data: dataDetails, refetch } = useQuery(
    ['get-refund-detail', id],
    () => RefundApi.getDetail({ id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { mutate: mutateChangeStatus } = useMutation(
    (status: any) => {
      if (status === 'approve') return RefundApi.approve({ id });
      return RefundApi.reject({ id });
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleChangeStatus = (status: 'reject' | 'approve') => {
    const text = {
      approve: 'duyệt',
      reject: 'từ chối',
    };
    confirm.warning({
      title: `Xác nhận ${text[status]} hoàn tiền`,
      content: (
        <div className="break-word">
          <p>Bạn có chắc chắn muốn {text[status]} hoàn tiền</p>
          <p>
            <b>{dataDetails?.code}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateChangeStatus(status);
      },
    });
  };

  const [activeKeyModule, setActiveKeyModule] = useState('1');

  const detail_1 = [
    {
      label: 'Mã đơn',
      value: dataDetails?.code,
    },
    {
      label: 'Họ và tên',
      value: (
        <HoverQuickView
          avatarUrl={dataDetails?.user?.avatar}
          code={dataDetails?.user?.code}
          email={dataDetails?.user?.email}
          phone={dataDetails?.user?.phone}
          name={dataDetails?.user?.full_name}
        >
          {dataDetails?.user?.full_name}
        </HoverQuickView>
      ),
    },
    {
      label: 'Khóa học',
      value: (
        <HoverQuickView
          avatarUrl={dataDetails?.course?.image_url}
          code={dataDetails?.course?.code}
          name={dataDetails?.course?.name}
        >
          {dataDetails?.course?.name}
        </HoverQuickView>
      ),
    },
    {
      label: 'Giảng viên',
      value: (
        <HoverQuickView
          avatarUrl={dataDetails?.lecturer?.avatar}
          code={dataDetails?.lecturer?.code}
          email={dataDetails?.lecturer?.email}
          phone={dataDetails?.lecturer?.phone}
          name={dataDetails?.lecturer?.full_name}
        >
          {dataDetails?.lecturer?.full_name}
        </HoverQuickView>
      ),
    },
    {
      label: 'Danh mục khóa học',
      value: dataDetails?.course_category?.name,
    },
    {
      label: 'Loại khóa học',
      value: COURSE_TYPE[dataDetails?.type_course],
    },
    {
      label: 'Loại hoàn tiền',
      value: TYPE_REFUND[dataDetails?.classification],
    },
  ];
  const detail_2 = [
    {
      label: 'Mã khóa học',
      value: (
        <HoverQuickView
          avatarUrl={dataDetails?.course?.image_url}
          code={dataDetails?.course?.code}
          name={dataDetails?.course?.name}
        >
          {dataDetails?.course?.code}
        </HoverQuickView>
      ),
    },
    {
      label: 'Giá trị hoàn tiền',
      value: formatCurrency(dataDetails?.course?.price),
    },
    {
      label: 'Ngày yêu cầu',
      value: formatDate(dataDetails?.created_at),
    },
    {
      label: 'Trạng thái',
      value: (
        <Tag color={STATUS_REFUND_COLOR[dataDetails?.status]}>
          {STATUS_REFUND[dataDetails?.status]}
        </Tag>
      ),
    },
  ];

  const itemModule = Object.entries({
    1: 'Phần 1',
    2: 'Phần 2',
    3: 'Phần 3',
    4: 'Phần 4',
    5: 'Phần 5',
    6: 'Phần 6',
  }).map(([key, label]) => ({
    key,
    label,
  }));

  const BreadcrumbItem: ItemType[] = [
    {
      title: (
        <a
          onClick={() => navigate(REFUND_URL.list.path)}
          className="!text-blue-400 hover:!text-blue-600"
        >
          Danh sách hoàn tiền
        </a>
      ),
    },
    {
      title: 'Chi tiết hoàn tiền',
    },
  ];

  useEffect(() => {
    if (id) refetch();
  }, [id]);

  return (
    <div className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <IconButton
              icon={<ArrowSmallLeftOutlined />}
              callBack={() => navigate(-1)}
            />
            <Breadcrumb separator="/" items={BreadcrumbItem} />
          </div>
          <div className="page-header-v2__function">
            {dataDetails?.status === 'pending' && (
              <>
                <Button
                  className="page-header-v2-btn"
                  onClick={() => handleChangeStatus('approve')}
                >
                  Duyệt
                </Button>
                <Button
                  type="danger"
                  className="page-header-v2-btn"
                  onClick={() => handleChangeStatus('reject')}
                >
                  Từ chối
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="page-content-v2">
        <div className="bg-white rounded-[10px] p-5 shadow">
          <CardFormV2 title="Thông tin chung">
            <Row className="grid grid-cols-2 gap-2.5">
              <div>
                {detail_1.map((item, index) => (
                  <div className={'flex items-start mb-2.5'} key={index}>
                    <div className="detail-key">{item.label}</div>
                    <div className="detail-value">{item.value}</div>
                  </div>
                ))}
              </div>
              <div>
                {detail_2.map((item, index) => (
                  <div className={'flex items-start mb-2.5'} key={index}>
                    <div className="detail-key">{item.label}</div>
                    <div className="detail-value">{item.value}</div>
                  </div>
                ))}
              </div>
            </Row>
          </CardFormV2>
          <CardFormV2 title="Đánh giá">
            <Row className="grid-cols-2 gap-2.5">
              <div className={'flex items-start'}>
                <div className="detail-key">Đánh giá</div>
                <div className="detail-value">
                  <div className="flex gap-1 text-[#E3A008] items-center">
                    <div className="flex">
                      {[1, 2, 3, 4, 5].map((rate) =>
                        rate <= Number(dataDetails?.rating?.star_count) ? (
                          <StarOutlined className="size-4 fill-[#E3A008]" />
                        ) : (
                          <StarOutlined className="size-4" />
                        ),
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={'flex items-start'}>
                <div className="detail-key">Thời gian đánh giá</div>
                <div className="detail-value">
                  {formatDate(
                    dataDetails?.rating?.created_at,
                    DATE_TIME_FORMAT,
                  )}
                </div>
              </div>
              <div className={'flex items-start col-span-2'}>
                <div className="detail-key">Nội dung đánh giá</div>
                <div className="detail-value">
                  {dataDetails?.rating?.content}
                </div>
              </div>
              <div className={'flex items-start col-span-2'}>
                <div className="detail-key">Hình ảnh/video đánh giá</div>
                <div className="detail-value flex gap-x-2.5">
                  {dataDetails?.rating?.attachments?.map((media) => (
                    <DetectMedia
                      src={media?.url}
                      type={media?.type_file}
                      className="w-[100px] h-[100px] rounded overflow-hidden"
                    />
                  ))}
                </div>
              </div>
            </Row>
          </CardFormV2>
          <CardFormV2 title="Quá trình học">
            <div className="border rounded overflow-hidden">
              <Row className="grid-cols-5 gap-2.5">
                <Col className="w-full bg-[#F3F4F6]">
                  <Tabs
                    activeClassName="bg-white"
                    className="bake-tab__tab-container border-r-0"
                    itemClassName="bake-tab__tab-item border-b"
                    tabPosition="left"
                    items={itemModule}
                    activeKey={activeKeyModule}
                    onChange={(key) => setActiveKeyModule(key)}
                  />
                </Col>
                <Col className="col-span-4 p-4 ">
                  <div className="flex flex-col gap-2.5">
                    <div className="rounded-md p-2.5 border border-blue-500 flex flex-col gap-y-2.5">
                      <div className="flex justify-between">
                        <p>Bài 1</p>
                        <div className="flex gap-x-2.5">
                          <span>7 điểm</span>
                          <p className="text-green-500 uppercase">
                            Học viên đã đạt
                          </p>
                        </div>
                      </div>
                      <p>Nội dung</p>
                      <span className="text-blue-600">
                        https://hotanbinh.com
                      </span>
                      <div className="flex gap-2.5">
                        <video className="w-[100px] h-[100px] bg-black" />
                      </div>
                    </div>
                    <div className="rounded-md p-2.5 border border-blue-500 flex flex-col gap-y-2.5">
                      <div className="flex justify-between">
                        <p>Bài 1</p>
                        <div className="flex gap-x-2.5">
                          <span>7 điểm</span>
                          <p className="text-green-500 uppercase">
                            Học viên đã đạt
                          </p>
                        </div>
                      </div>
                      <p>Nội dung</p>
                      <span className="text-blue-600">
                        https://hotanbinh.com
                      </span>
                      <div className="flex gap-2.5">
                        <video className="w-[100px] h-[100px] bg-black" />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </CardFormV2>
        </div>
      </div>
    </div>
  );
}

export default RefundDetail;
