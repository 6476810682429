import { useQuery } from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Badge,
  getQueryParams,
  PaginationProps,
  Tabs,
  updateQueryParams,
} from 'tera-dls';
import RefundApi from './api';
import { STATUS_REFUND } from './constants';
import RefundFilter from './containers/Filter';
import RefundSearch from './containers/Search';
import RefundTable from './containers/Table';
import { REFUND_URL } from './url';

function Refund() {
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);

  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(`${REFUND_URL.list.path}${paramString}`);
  };

  const {
    data: refundList,
    isLoading,
    refetch,
  } = useQuery(
    ['get-refund-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
        status: queryParams?.status === 'all' ? undefined : queryParams?.status,
      };
      return RefundApi.getList({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
      onSuccess: (data) => {
        if (Number(data?.last_page) < (Number(queryParams?.page) || 1)) {
          handleUpdateQueryParams({ page: data?.last_page });
        }
      },
    },
  );

  const { data: summary, refetch: refetchSummary } = useQuery(
    ['get-refund-summary', queryParams],
    () =>
      RefundApi.getSummaryList({
        ...queryParams,
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize = Number(pageSize) !== Number(refundList?.limit || 10);

    handleUpdateQueryParams({
      page: isDiffPageSize ? 1 : page,
      limit: pageSize,
    });
  };

  const handleFilter = (values) => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const handleSearch = (value) => {
    handleUpdateQueryParams({ keyword: value?.keyword, page: 1 });
  };

  const handleChangeTab = (key: any): void => {
    handleUpdateQueryParams({ status: key, page: 1 });
  };

  const tabItems = useMemo(() => {
    const data = Object.keys(STATUS_REFUND)?.map((key) => ({
      key: key,
      label: (
        <h3 className="tab-table">
          <span>{STATUS_REFUND[key]}</span>
          <Badge
            showZero
            count={summary?.find((i) => i.status == key)?.total_count ?? 0}
          />
        </h3>
      ),
    }));
    return [
      {
        key: 'all',
        label: (
          <h3 className="tab-table">
            <span>{'Tất cả'}</span>
            <Badge
              showZero
              count={summary?.find((i) => i.status === 'all')?.total_count ?? 0}
            />
          </h3>
        ),
      },
      ...data,
    ];
  }, [summary]);

  useEffect(() => {
    refetch();
    refetchSummary();
  }, []);

  return (
    <div className="tera-page">
      <HeaderViewList
        title="Danh sách hoàn tiền"
        onClickFilter={() => setIsOpenFilter(true)}
        actionLeftRender={<RefundSearch onSearch={handleSearch} />}
        middleContent={
          <Tabs
            onChange={handleChangeTab}
            items={tabItems}
            activeKey={queryParams?.status || 'all'}
            className="mb-0 pt-0 bg-white rounded-t-md"
          />
        }
        filterCount={{
          filter: queryParams,
          params: ['transaction_type', 'methods', 'date', 'status'],
        }}
      >
        <RefundTable
          rowKey={'id'}
          data={refundList?.data || [{ id: 1 }]}
          loading={isLoading}
          pagination={{
            onChange: handleChangePage,
            total: refundList?.total || 0,
            current: refundList?.current_page,
            pageSize: refundList?.per_page || 10,
            to: refundList?.to,
            from: refundList?.from || 10,
          }}
        />
      </HeaderViewList>

      {isOpenFilter && (
        <RefundFilter
          open={isOpenFilter}
          onClose={() => setIsOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
    </div>
  );
}

export default Refund;
