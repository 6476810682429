import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const AffiliateEndpoint = `${endpoint}/bake-teach/affiliate`;
const AffiliateApi = {
  getList: async (params) =>
    await api
      .get(`${AffiliateEndpoint}/list`, params)
      .then((data) => data?.data?.data),
  getListOrder: async (params) =>
    await api
      .get(`${AffiliateEndpoint}/list-order`, params)
      .then((result) => result?.data?.data),
};
export default AffiliateApi;
