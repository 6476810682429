import Filter from '_common/component/Filter';
import RangeNumber from '_common/dof/Control/RangeNumber';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectCourseLesson from '_common/dof/Select/SelectCourseLesson';
import SelectCourseSection from '_common/dof/Select/SelectCourseSection';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface IProps {
  open: boolean;
  initialValue: any;
  onClose: () => void;
  onFilter: (value) => void;
  courseId: number;
}

function StudentProductFilter({
  open,
  initialValue,
  onClose,
  onFilter,
  courseId,
}: IProps) {
  const form = useForm();
  const section_id = form.watch('section_id');
  const handleSubmitForm = (values) => {
    onFilter(values), onClose();
  };

  const handleReset = () => {
    const values = {
      section_id: null,
      lesson_id: null,
      from_score: null,
      to_score: null,
    };
    onFilter(values);
    onClose();
  };

  useEffect(() => {
    if (!initialValue) return;
    form.reset(initialValue);
  }, [initialValue]);

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={handleSubmitForm} isLoading={false}>
        <FormTeraItem name="section_id" label="Phần học">
          <SelectCourseSection
            paramsApi={{
              include_id: initialValue?.section_id,
              course_id: courseId,
            }}
            onChangeCustom={() => form.setValue('lesson_id', null)}
          />
        </FormTeraItem>
        <FormTeraItem name="lesson_id" label="Phần học">
          <SelectCourseLesson
            paramsApi={{
              include_id: initialValue?.lesson_id,
              section_id: section_id,
            }}
            disabled={!section_id}
          />
        </FormTeraItem>
        <FormTeraItem name="" label="Số điểm">
          <RangeNumber
            startFormName="from_score"
            endFormName="to_score"
            endInputProps={{
              min: 0,
              max: 10,
              placeholder: 'Đến khoảng',
            }}
            startInputProps={{
              min: 0,
              max: 10,
              placeholder: 'Từ khoảng',
            }}
          />
        </FormTeraItem>
        <a
          className="text-red-500 text-xxs font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default StudentProductFilter;
