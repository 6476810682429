import CkEditor, {
  convertClassNameToInlineStyle,
} from '_common/component/CkEditor';
import { useTeraForm } from '_common/dof/FormTera/TeraFormContext';
import { useTeraFormItem } from '_common/dof/FormTera/TeraItemContext';
import { Controller } from 'react-hook-form';

const CkeditorForm = () => {
  const { form } = useTeraForm();
  const { item, rules } = useTeraFormItem();
  const { control } = form;

  return (
    <div className="!overflow-x-hidden tailwind-preflight">
      <Controller
        {...item}
        control={control}
        rules={rules}
        render={({ field }) => {
          return (
            <CkEditor
              data={field?.value}
              items={[
                // 'heading',
                // '|',
                'bold',
                'italic',
                'fontColor',
                'fontSize',
                'fontFamily',
                'fontBackgroundColor',
                'highlight',
                'alignment',
                'bulletedList',
                'numberedList',
              ]}
              onChange={(val) =>
                field?.onChange(
                  !val || val == '' ? val : convertClassNameToInlineStyle(val),
                )
              }
              editorCallback={(editor) =>
                editor.editing.view.change((writer) => {
                  writer.setStyle(
                    'height',
                    '200px',
                    editor.editing.view.document.getRoot(),
                  );
                })
              }
            />
          );
        }}
      />
    </div>
  );
};

export default CkeditorForm;
