import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import { useEffect } from 'react';
import { BookmarkOutlined, notification, Row, Spin } from 'tera-dls';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ButtonCommon from '_common/component/Button';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { MAXIMUM_CURRENCY, MAXIMUM_QUANTITY } from '_common/constants/common';
import { messageWarning } from '_common/constants/message';
import CheckBox from '_common/dof/Control/CheckBox';
import InputNumber from '_common/dof/Control/InputNumber';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { usePrompt } from '_common/hooks/usePrompt';
import { useForm } from 'react-hook-form';
import AffiliateConfigApi from './api';
import { APPLY_TYPE, PROMOTION_TYPE } from './constants';
import { pick } from 'lodash';

export interface IFormModel {
  open: boolean;
  value?: string | number;
}

const AffiliateConfig = () => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      minimum_withdrawal: 50000,
      commission_per: 1,
      apply_for: ['online_course', 'offline_course'],
      time_expire_link: null,
      discount_type: 'money',
      discount_value: null,
      have_promotional_affiliate: true,
      apply_for_first_time: true,
    },
  });
  const {
    formState: { isDirty },
  } = form;
  const queryClient = useQueryClient();
  const discount_type = form.watch('discount_type');
  const have_promotional_affiliate = form.watch('have_promotional_affiliate');
  const {
    data: response,
    refetch,
    isFetching,
  } = useQuery(
    ['get-affiliate-config'],
    () =>
      AffiliateConfigApi.getConfig({
        page: 1,
        limit: 10,
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
  }, []);

  usePrompt(
    <p className="break-word">
      <p>{messageWarning.WARNING_EXIT_1}</p>
      <p>{messageWarning.WARNING_EXIT_2}</p>
    </p>,
    isDirty,
  );

  const { mutate: mutateUpdate, isLoading } = useMutation(
    (variables: any) => AffiliateConfigApi.save(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-affiliate-config']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleSubmitForm = (values) => {
    mutateUpdate({
      params: {
        ...values,
        have_promotional_affiliate: Number(values?.have_promotional_affiliate),
        apply_for_first_time: Number(values?.apply_for_first_time),
      },
    });
  };

  useEffect(() => {
    if (response) {
      const data = pick(response, [
        'apply_for',
        'commission_per',
        'minimum_withdrawal',
        'time_expire_link',
        'discount_type',
        'discount_value',
      ]);
      form.reset({
        ...data,
        have_promotional_affiliate: Boolean(
          response?.have_promotional_affiliate,
        ),
        apply_for_first_time: Boolean(response?.apply_for_first_time),
      });
    }
  }, [response]);

  const typeOptions = Object.entries(APPLY_TYPE).map(([value, label]) => ({
    value,
    label,
  }));

  const promotionTypeOptions = Object.entries(PROMOTION_TYPE).map(
    ([value, label]) => ({
      value,
      label,
    }),
  );

  return (
    <>
      <div className="tera-page">
        <HeaderViewListV2
          title="CẤU HÌNH AFFILIATES"
          buttonAddRender={() => (
            <>
              <ButtonCommon
                htmlType="submit"
                type="success"
                className="page-header-btn bg-green-500"
                icon={<BookmarkOutlined className="w-4" />}
                onClick={form.handleSubmit(handleSubmitForm)}
                loading={isLoading || isFetching}
                disabled={!isDirty || isLoading || isFetching}
              >
                Lưu
              </ButtonCommon>
            </>
          )}
        >
          <div className="page-content-v2 p-2.5 !rounded-sm h-full !mb-2.5 !gap-y-0">
            <Spin spinning={isFetching || isLoading}>
              <FormTera form={form}>
                <Row className="grid-cols-2 gap-3">
                  <FormTeraItem
                    name="minimum_withdrawal"
                    label="Số tiền tối thiểu được rút"
                  >
                    <InputNumber
                      placeholder="Vui lòng nhập"
                      min={0}
                      max={MAXIMUM_CURRENCY}
                      suffix={<span className="mr-5">đ</span>}
                    />
                  </FormTeraItem>
                  <FormTeraItem
                    name="time_expire_link"
                    label="Thời gian tồn tại link giới thiệu"
                  >
                    <InputNumber
                      step={1}
                      min={0}
                      max={MAXIMUM_QUANTITY}
                      parser={(value) => value.replace(/\.\d*/g, '')}
                      placeholder="Vui lòng nhập"
                      suffix={<span className="mr-5">Ngày</span>}
                    />
                  </FormTeraItem>
                  <FormTeraItem name="apply_for" label="Áp dụng hoa hồng cho">
                    <Select mode="multiple" options={typeOptions} />
                  </FormTeraItem>
                  <FormTeraItem
                    name="commission_per"
                    label="% hoa hồng nhận được"
                    help={'<100'}
                  >
                    <InputNumber
                      min={1}
                      max={100}
                      suffix={<span className="mr-5">%</span>}
                    />
                  </FormTeraItem>
                  <FormTeraItem
                    name="have_promotional_affiliate"
                    label=""
                    className="col-span-1"
                  >
                    <CheckBox labelClassName="font-normal text-[13px]">
                      Ưu đãi người nhập mã
                    </CheckBox>
                  </FormTeraItem>
                  <FormTeraItem
                    name="apply_for_first_time"
                    label=""
                    className="col-span-1"
                  >
                    <CheckBox labelClassName="font-normal text-[13px]">
                      Áp dụng lần đầu
                    </CheckBox>
                  </FormTeraItem>
                  {have_promotional_affiliate && (
                    <>
                      <FormTeraItem name="discount_type" label="Loại giảm giá">
                        <Select
                          options={promotionTypeOptions}
                          onChangeCustom={(val) => {
                            if (val === 'per') {
                              form.setValue('discount_value', 1);
                              return;
                            }
                            form.setValue('discount_value', 0);
                          }}
                        />
                      </FormTeraItem>

                      {discount_type == 'per' ? (
                        <FormTeraItem
                          name="discount_value"
                          label="% hoa hồng nhận được"
                          help={'<100'}
                        >
                          <InputNumber
                            min={1}
                            max={100}
                            suffix={<span className="mr-5">%</span>}
                          />
                        </FormTeraItem>
                      ) : (
                        <FormTeraItem
                          name="discount_value"
                          label="Giá trị ưu đãi"
                          // rules={[
                          //   {
                          //     required: messageValidate.emptyText,
                          //   },
                          // ]}
                        >
                          <InputNumber
                            placeholder="Vui lòng nhập"
                            min={0}
                            max={MAXIMUM_CURRENCY}
                            suffix={<span className="mr-5">đ</span>}
                          />
                        </FormTeraItem>
                      )}
                    </>
                  )}
                </Row>
              </FormTera>
            </Spin>
          </div>
        </HeaderViewListV2>
      </div>
    </>
  );
};

export default AffiliateConfig;
